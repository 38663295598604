import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_PREDICTION_QUESTIONS]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state).startLoadingEntity('predictionQuestions').get()
    },
    [FULFILLED](state, { payload }) {
      const { predictionQuestions } = payload
      return EntityFacade(state).loadPredictionQuestions(predictionQuestions).finishLoadingEntity('predictionQuestions').get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state).loadPredictionQuestions([]).finishLoadingEntity('predictionQuestions').get()
    }
  },
  [ActionTypes.CREATE_PREDICTION_QUESTION]: {
    [FULFILLED](state, { payload }) {
      const { createPredictionQuestion } = payload
      return EntityFacade(state).createPredictionQuestion(createPredictionQuestion).get()
    }
  },
  [ActionTypes.UPDATE_PREDICTION_QUESTION]: {
    [FULFILLED](state, { meta }) {
      const { question } = meta
      return EntityFacade(state).updatePredictionQuestion(question).get()
    }
  },
  [ActionTypes.DELETE_PREDICTION_QUESTION]: {
    [FULFILLED](state, { meta }) {
      const { question } = meta
      return EntityFacade(state).deletePredictionQuestion(question).get()
    }
  }
}
