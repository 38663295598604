import normalizeData from '../../helpers/normalizeData'

export default (mergeWith) => {
  return {
    loadOrganizations(data) {
      mergeWith({ organizations: normalizeData(data, null, '_id') })
      return this
    }
  }
}
