import normalizeData from '../../helpers/normalizeData'

export default mergeWith => {
  return {
    loadLeague(leagueList) {
      mergeWith(({ leagues }) => {
        leagues = normalizeData(leagueList)
        return { leagues }
      })
      return this
    }
  }
}
