import normalizeData from '../../helpers/normalizeData'

export default mergeWith => {
  return {
    loadAdminUsers(adminUserList) {
      mergeWith(({ adminUsers }) => {
        adminUsers = normalizeData(adminUserList)
        return { adminUsers }
      })
      return this
    }
  }
}
