export default [
  { title: 'Commentary', type: 'commentary' },
  { title: 'Goal', type: 'goal', shouldUpdateMatch: true, scoreIncrementFactor: 1 },
  { title: 'Penalty Awarded', type: 'penalty_awarded' },
  { title: 'Penalty Missed', type: 'penalty_missed' },
  { title: 'Yellow Card', type: 'card_yellow' },
  { title: 'Card', type: 'card', is_dummy: true },
  { title: 'Red Card', type: 'card_red' },
  { title: 'Second Yellow ', type: 'card_yr' },
  { title: 'Substitution', type: 'substitution' },
  { title: 'Injury', type: 'injury' },
  { title: 'Injury Time', type: 'injury_time' },
  { title: 'Shootout Goal', type: 'shootout_goal', penaltyIncrementFactor: 1, scoreIncrementFactor: 1, shouldUpdateMatch: true },
  {
    title: 'Start Match',
    type: 'start_first_half',
    realType: 'match_started',
    is_dummy: true,
    shouldUpdateMatch: true,
    matchLifeCycleFact: true,
    matchUpdate: {
      status: 'inprogress',
      period: 'P1',
      periodStatus: 'period_started',
      adminUser: true,
      periodTime: true,
      periodTAFactor: 0
    },
    splitedFacts: [
      { type: 'match_started', period: '' },
      { type: 'period_started', period: 'P1', periodStatus: 'period_started', periodTime: true, periodTAFactor: 0 }
    ]
  },
  {
    title: 'End 1st Half',
    type: 'end_first_half',
    realType: 'period_started',
    matchLifeCycleFact: true,
    is_dummy: true,
    shouldUpdateMatch: true,
    showScoreBoard: true,
    splitedFacts: [
      { type: 'period_over', period: 'P1', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'H', periodStatus: 'period_started', periodTime: true, periodTAFactor: 0 }
    ],
    matchUpdate: {
      period: 'H',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'Start 2nd Half',
    type: 'start_second_half',
    realType: 'period_over',
    is_dummy: true,
    shouldUpdateMatch: true,
    matchLifeCycleFact: true,
    splitedFacts: [
      { type: 'period_over', period: 'H', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'P2', periodStatus: 'period_started', periodTime: true, periodTAFactor: 45 }
    ],
    matchUpdate: {
      period: 'P2',
      periodStatus: 'period_started',
      periodTime: true,
      periodTAFactor: 45
    }
  },
  {
    title: 'End Match',
    type: 'match_ended_without_extra_time',
    shouldUpdateMatch: true,
    realType: 'match_ended',
    matchLifeCycleFact: true,
    showScoreBoard: true,
    splitedFacts: [
      { type: 'period_over', period: 'P2', periodStatus: 'period_over', periodTime: true },
      { type: 'match_ended', period: '' }
    ],
    matchUpdate: {
      status: 'closed',
      periodStatus: 'period_over'
    }
  },
  {
    title: 'Match to Extra Time',
    type: 'match_to_extra_time',
    is_dummy: true,
    shouldUpdateMatch: true,
    showScoreBoard: true,
    splitedFacts: [
      { type: 'period_over', period: 'P2', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'PX1', periodStatus: 'period_started', periodTime: true }
    ],
    matchUpdate: {
      period: 'PX1',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'Start 1st Extra Time',
    type: 'start_first_extra_time',
    is_dummy: true,
    shouldUpdateMatch: true,
    splitedFacts: [
      { type: 'period_over', period: 'PX1', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'X1', periodStatus: 'period_started', periodTime: true, periodTAFactor: 90 }
    ],
    matchUpdate: {
      period: 'X1',
      periodStatus: 'period_started',
      periodTime: true,
      periodTAFactor: 90
    }
  },
  {
    title: 'End 1st Extra Time',
    type: 'end_first_extra_time',
    is_dummy: true,
    shouldUpdateMatch: true,
    showScoreBoard: true,
    splitedFacts: [
      { type: 'period_over', period: 'X1', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'PX2', periodStatus: 'period_started', periodTime: true }
    ],
    matchUpdate: {
      period: 'PX2',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'Start 2nd Extra Time',
    type: 'start_second_extra_time',
    is_dummy: true,
    shouldUpdateMatch: true,
    splitedFacts: [
      { type: 'period_over', period: 'PX2', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'X2', periodStatus: 'period_started', periodTime: true, periodTAFactor: 105 }
    ],
    matchUpdate: {
      period: 'X2',
      periodStatus: 'period_started',
      periodTime: true,
      periodTAFactor: 105
    }
  },
  {
    title: 'End Match',
    type: 'match_ended_without_penalties',
    showScoreBoard: true,
    shouldUpdateMatch: true,
    splitedFacts: [
      { type: 'period_over', period: 'X2', periodStatus: 'period_over', periodTime: true },
      { type: 'match_ended', period: '' }
    ],
    matchUpdate: {
      status: 'closed',
      periodStatus: 'period_over'
    }
  },
  {
    title: 'Match To Penalties',
    type: 'match_to_penalties',
    is_dummy: true,
    shouldUpdateMatch: true,
    showScoreBoard: true,
    splitedFacts: [
      { type: 'period_over', period: 'X2', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'P', periodStatus: 'period_started', periodTime: true }
    ],
    matchUpdate: {
      period: 'P',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'End Match',
    type: 'match_ended_after_penalties',
    shouldUpdateMatch: true,
    showScoreBoard: true,
    splitedFacts: [
      { type: 'period_over', period: 'P', periodStatus: 'period_over', periodTime: true },
      { type: 'match_ended', period: '' }
    ],
    matchUpdate: {
      status: 'closed',
      periodStatus: 'period_over'
    }
  },
  {
    title: 'End Match',
    type: 'match_ended',
    shouldUpdateMatch: true,
    showScoreBoard: true
  }
]
