import createPromisifiedFacade from 'facade-tools/lib/createPromisifiedFacade'
// import deep from 'deep-diff'
import { findIndex, sortBy, pickBy, reject } from 'lodash'

const initialState = {
  matchFacts: {},
  isFactRearraging: false,
  isFactUpdating: false,
}

const MatchFactFacade = createPromisifiedFacade((mergeWith) => {
  return {
    setIsFactRearraging(status) {
      mergeWith(({ isFactRearraging }) => {
        return { isFactRearraging: status }
      })
      return this
    },
    setFactUpdateStatus(status) {
      mergeWith(({ isFactRearraging }) => {
        return { isFactUpdating: status }
      })
      return this
    },
    loadFacts(matchCId, facts) {
      mergeWith(({ matchFacts }) => {
        facts = sortBy(facts, ['time'])
        matchFacts = {
          ...matchFacts,
          [matchCId]: facts,
        }
        return { matchFacts }
      })

      return this
    },
    addOrUpdateFacts(facts) {
      mergeWith(({ matchFacts }) => {
        for (let fact of facts) {
          fact = pickBy(fact, (v) => v !== null && v !== undefined)
          // there is a match info coming from Interval
          delete fact.match
          let { matchCId } = fact
          if (matchFacts && matchFacts[matchCId]) {
            let facts = [...matchFacts[matchCId]]

            let index = findIndex(facts, { customId: fact.customId })
            const tempIndex = findIndex(facts, (f) => {
              return f.inning === fact.inning && f.ball === fact.ball && f.over === fact.over && f.isTemp && f.type !== 'general'
            })
            // new facts don't have customIds, they are created on the server
            if (index === -1) index = findIndex(facts, { type: fact.type, inning: fact.inning, ball: fact.ball, over: fact.over, time: fact.time })
            if (index === -1 && tempIndex >= 0 && !fact.tempId) {
              facts.splice(tempIndex, 1)
            }
            if (index !== -1) {
              facts[index] = { ...facts[index], ...fact, isTemp: false }
              // the above checking is now not needed as it's only being added to the feed via RT response.
              // customId is now created in the client - cause of bad Cricket UX for admins
            } else {
              //(fact.customId || fact.tempId)
              facts = [fact, ...facts]
            }
            facts = sortBy(facts, ['time'])
            matchFacts = {
              ...matchFacts,
              [matchCId]: [...facts],
            }
          }
        }
        return { matchFacts }
      })
      return this
    },
    removeFactsFromStore(matchCId, condition) {
      mergeWith(({ matchFacts }) => {
        if (matchFacts && matchFacts[matchCId]) {
          let facts = [...matchFacts[matchCId]]
          facts = reject(facts, condition)
          matchFacts = {
            ...matchFacts,
            [matchCId]: [...facts],
          }
        }
        return { matchFacts }
      })
      return this
    },
    updateFactInStore(fact, condition) {
      mergeWith(({ matchFacts }) => {
        const { matchCId } = fact
        if (matchFacts && matchFacts[matchCId]) {
          let facts = [...matchFacts[matchCId]]
          let index = findIndex(facts, condition)
          if (index !== -1) {
            facts[index] = { ...facts[index], ...fact }
          }
          matchFacts = {
            ...matchFacts,
            [matchCId]: [...facts],
          }
        }
        return { matchFacts }
      })
      return this
    },
    removeTempFactOnSocketUpdation(matchCId, type) {
      mergeWith(({ matchFacts }) => {
        if (!matchFacts[matchCId]) {
          return { matchFacts }
        }
        let facts = [...matchFacts[matchCId]]
        const tempIndex = findIndex(facts, (f) => {
          return f.isTemp && f.type !== type
        })
        if (tempIndex >= 0) {
          facts.splice(tempIndex, 1)
        }
        matchFacts = {
          ...matchFacts,
          [matchCId]: [...facts],
        }
        return { matchFacts }
      })
      return this
    },
  }
}, initialState)

export default MatchFactFacade
