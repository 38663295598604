import { cloneDeep } from 'lodash'
import apollo from '../apollo'
import omitDeep from '../helpers/omitDeep'

const mutate = (mutation, variables = {}, others = {}) => {
  return new Promise((resolve, reject) => {
    apollo
      .mutate({
        mutation,
        variables,
        ...others
      })
      .then(({ data }) => {
        data = omitDeep(cloneDeep(data), '__typename')
        resolve(data)
      })
      .catch(reject)
  })
}

export default mutate
