import gql from 'graphql-tag'

export default gql`
  query adminUser {
    adminUser {
      commentaryMatchId
      videoTimer
      commentaryStartedOn
      videoId
      trailMatches
      role
      email
      firstName
      lastName
      customId
      listingPermission {
        sport
        leagueCId
      }
      organizationId
    }
  }
`
