import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.SOCKET_FACTS_CREATED]: (state, { payload }) => {
    const { facts } = payload
    return EntityFacade(state).updateMatchesViaSocket(facts).get()
  },
  [ActionTypes.SOCKET_SCORE_UPDATED]: (state, { payload }) => {
    const { matchCId, ...score } = payload
    return EntityFacade(state).updateMatchScoreViaSocket(matchCId, score).get()
  },
  [ActionTypes.SOCKET_CRICKET_STATS_UPDATED]: (state, { payload }) => {
    const { matchCId, ...cricketStats } = payload
    return EntityFacade(state).updateCricketStatsViaSocket(matchCId, cricketStats).get()
  },
  [ActionTypes.SOCKET_CRICKET_STATUSES_UPDATED]: (state, { payload }) => {
    return EntityFacade(state).updateCricketStatusesViaSocket(payload).get()
  },
  [ActionTypes.SOCKET_MATCH_UPDATED]: (state, { payload }) => {
    return EntityFacade(state).updateMatchDataViaSocket(payload).get()
  },
  [ActionTypes.SOCKET_ASSIGNEDADMINS_UPDATED]: (state, { payload }) => {
    return EntityFacade(state).updateMatchDataViaSocket(payload).get()
  }
}
