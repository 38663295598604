import { sortBy } from 'lodash'
import parseCommon from './sport/common'
import parseSoccer from './sport/soccer'
import parseCricket from './sport/cricket'

const Parser = (matches, facts, teams) => {
  facts = sortBy(facts, ['time'])

  for (const fact of facts) {
    const { matchCId } = fact
    if (matches[matchCId] && teams) {
      let match = { ...matches[matchCId] }
      const { sport } = match
      match = parseCommon(match, fact)
      switch (sport) {
        case 'soccer':
          match = parseSoccer(match, fact)
          break
        case 'cricket':
          match = parseCricket(match, fact, teams.byId)
          break
        default:
          break
      }
      matches[matchCId] = { ...match }
    }
  }
  return matches
}

export default Parser
