import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_ADDITIONAL_TABLE_DATA]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state).startLoadingEntity('additionalTableData').get()
    },
    [FULFILLED](state, { payload }) {
      const { additionalTableData } = payload
      return EntityFacade(state).loadAdditionalTableData(additionalTableData).finishLoadingEntity('additionalTableData').get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state).loadAdditionalTableData([]).finishLoadingEntity('additionalTableData').get()
    },
  },
  [ActionTypes.RESET_ADDITIONAL_TABLE]: (state) => {
    return EntityFacade(state).resetAdditionalTable().get()
  },
}
