import React from 'react'

const ScreenLoader = ({ error }) => {
  if (error) {
    console.log('---error', error)
    return <div>Sorry, there was a problem to load this page.</div>
  }

  return <div>Loading</div>
}

export default ScreenLoader
