import createFacade from 'facade-tools/lib/createFacade'
import matchVideos from '../values/matchVideos'

const initialState = { matchVideos }

const matchVideoFacade = createFacade((mergeWith) => {
  return {}
}, initialState)

export default matchVideoFacade
