import { css } from 'emotion'

const SidebarStyles = css`
  .sidebar {
    position: fixed;
    height: calc(100% - 46px) !important;
    display: block;
    width: 185px;
    background: #1b2133;
    padding-right: 0px;
    z-index: 9999;
    overflow-y: scroll;
  }
  .sidebar.collapsed {
    width: 60px;
  }
  .sidebar-margin {
    margin-left: 185px;
  }
  .sidebar-margin.collapsed {
    margin-left: 60px;
  }
  .sidebar .nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }
  .sidebar .nav li {
    line-height: 40px;
    width: 100%;
  }
  .sidebar .nav li.active-link {
    border-left: 5px solid #f82e2e;
    background: #0c1224;
  }
  .sidebar .nav li.inactive-link {
    border-left: 5px solid #0c1224;
    background: #0c1224;
  }
  .sidebar .nav li a {
    cursor: pointer;
    padding: 0;
    width: 100%;
    padding-left: 10px;
    color: #fff;
    position: relative;
    display: block;
    font-size: 12px;
  }
  .sidebar .nav li a:hover {
    text-decoration: none;
  }
  .menu-seperator {
    border: 1px #717171 solid;
    width: 100%;
    margin: 0px;
  }
  .build-info {
    position: fixed;
    bottom: 10px;
    color: white;
    width: 170px;
    text-align: center;
    font-size: 12px;
    z-index: 999999;
    left: 0;
    background: #1b2133;
    height: 35px;
  }
  .build-info.collapsed {
    width: 45px;
  }
`
export default SidebarStyles
