import createPromisifiedFacade from 'facade-tools/lib/createPromisifiedFacade'
import normalizeData from '../helpers/normalizeData'

const initialState = {
  matches: null,
  allLeaguesLoaded: false
}

const leagueFacade = createPromisifiedFacade(mergeWith => {
  return {
    loadMatches(matchList, leagueCId) {
      mergeWith(({ matches }) => {
        matchList = normalizeData(matchList)
        const matchCIds = matches && matches[leagueCId] ? [...matches[leagueCId], ...matchList.allIds] : [...matchList.allIds]
        matches = {
          ...matches,
          [leagueCId]: matchCIds
        }
        return { matches }
      })
      return this
    },
    allLeaguesLoaded(isAllLeagueLoaded) {
      mergeWith(({ allLeaguesLoaded }) => {
        allLeaguesLoaded = true
        return { allLeaguesLoaded }
      })
      return this
    }
  }
}, initialState)

export default leagueFacade