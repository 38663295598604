import createPromisifiedFacade from 'facade-tools/lib/createPromisifiedFacade'
import normalizeData from '../helpers/normalizeData'

const initialState = {
  matches: [],
  allSeasonsLoaded: false
}

const seasonFacade = createPromisifiedFacade(mergeWith => {
  return {
    loadMatches(matchList, seasonCIds) {
      mergeWith(({ matches }) => {
        if (seasonCIds && seasonCIds.length === 1) {
          matchList = normalizeData(matchList)
          matches = {
            ...matches,
            [seasonCIds[0]]: matchList.allIds
          }
          return { matches }
        }
      })
      return this
    },
    createMatch(match, seasonCId) {
      mergeWith(({ matches }) => {
        if (matches[seasonCId]) {
          matches[seasonCId] = [...matches[seasonCId], match.customId]
        }
        return { matches }
      })
      return this
    },
    allSeasonsLoaded(isAllSeasonLoaded) {
      mergeWith(({ allSeasonsLoaded }) => {
        allSeasonsLoaded = true
        return { allSeasonsLoaded }
      })
      return this
    }
  }
}, initialState)

export default seasonFacade
