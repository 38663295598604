const config = {
  api: {
    url: process.env.REACT_APP_API_URL
  },
  socket: {
    url: process.env.REACT_APP_SOCKET_URL,
    key: process.env.REACT_APP_SOCKET_KEY
  },
  oldAdminUrl: process.env.REACT_APP_OLD_ADMIN_URL,
  buildNumber: process.env.REACT_APP_BUILD,
  sentry: {
    dsn: 'https://63231cc254794cc8a1fc6d069de8ec2c@o59636.ingest.sentry.io/5446463',
    tracesSampleRate: 1.0
  }
}
export default config
