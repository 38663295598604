import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_PREDICTION_COMPETITIONS]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state).startLoadingEntity('predictionCompetitions').get()
    },
    [FULFILLED](state, { payload }) {
      const { predictionCompetitions } = payload
      return EntityFacade(state).loadPredictionCompetitions(predictionCompetitions).finishLoadingEntity('predictionCompetitions').get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state).loadPredictionCompetitions([]).finishLoadingEntity('predictionCompetitions').get()
    }
  },
  // [ActionTypes.CREATE_PREDICTION_QUESTION]: {
  //   [FULFILLED](state, { payload }) {
  //     const { createPredictionQuestion } = payload
  //     return EntityFacade(state).createPredictionQuestion(createPredictionQuestion).get()
  //   }
  // },
  [ActionTypes.UPDATE_PREDICTION_COMPETITION]: {
    [FULFILLED](state, { meta }) {
      const { competition } = meta
      return EntityFacade(state).updatePredictionCompetition(competition).get()
    }
  }
  // [ActionTypes.DELETE_PREDICTION_QUESTION]: {
  //   [FULFILLED](state, { meta }) {
  //     const { question } = meta
  //     return EntityFacade(state).deletePredictionQuestion(question).get()
  //   }
  // }
}
