import { findIndex } from 'lodash'
const removeNormalizedData = (object, initialData, key = 'customId') => {
  const i = findIndex(initialData.allIds, (id) => {
    return object[key] === id
  })
  if (i >= 0) {
    initialData.allIds.splice(i, 1)
    delete initialData.byId[object[key]]
  }
  const newData = { allIds: initialData.allIds, byId: initialData.byId }
  return newData
}

export default removeNormalizedData
