import typeToReducer from 'type-to-reducer'
import { PENDING, REJECTED, FULFILLED } from '../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../constants/ActionType'
import LineupFacade from '../facades/lineup'

export default typeToReducer(
  {
    [ActionTypes.LOAD_LINEUP]: {
      [PENDING](state, { payload }) {
        return LineupFacade(state)
          .startLoading()
          .get()
      },
      [FULFILLED](state, { meta, payload }) {
        const { matchCId } = meta
        const { lineup } = payload
        return LineupFacade(state)
          .load(matchCId, lineup)
          .finishLoading()
          .get()
      },
      [REJECTED](state, { payload, meta }) {
        const { matchCId } = meta
        return LineupFacade(state)
          .load(matchCId, {})
          .finishLoading()
          .get()
      }
    },
    [ActionTypes.SAVE_LINEUP]: {
      [PENDING](state, { payload }) {
        return LineupFacade(state)
          .startLoading()
          .get()
      },
      [FULFILLED](state, { meta, payload }) {
        const { matchCId } = meta
        const { saveLineup } = payload
        return LineupFacade(state)
          .load(matchCId, saveLineup)
          .finishLoading()
          .get()
      },
      [REJECTED](state, { payload, meta }) {
        const { matchCId } = meta
        return LineupFacade(state)
          .load(matchCId, {})
          .finishLoading()
          .get()
      }
    },
    [ActionTypes.OPEN_LINEUP_EDIT_POPUP]: (state, { payload }) => {
      const { matchCId } = payload
      return LineupFacade(state)
        .openLineupEditPopup(matchCId)
        .get()
    },
    [ActionTypes.CLOSE_LINEUP_EDIT_POPUP]: (state, { payload }) => {
      const { matchCId } = payload
      return LineupFacade(state)
        .closeLineupEditPopup(matchCId)
        .get()
    }
  },
  LineupFacade().get()
)
