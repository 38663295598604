import switchHomeAway from '@tribe/switch-homeaway'
import prepareCricketMatch from '@tribe/cricket'

import normalizeData from '../../helpers/normalizeData'
import getTeamsFromMatches from '../../helpers/match/getTeamsFromMatches'
import Parser from '../../lib/parser'
import getVenuesFromMatches from '../../helpers/match/getVenuesFromMatches'
import getPlayersFromTeams from '../../helpers/match/getPlayersFromTeams'

export default (mergeWith) => {
  return {
    loadMatch(match) {
      mergeWith(({ matches, teams, players }) => {
        let byId = matches && matches.byId ? matches.byId : {}
        let allIds = matches && matches.allIds ? matches.allIds : []
        const teamList = getTeamsFromMatches([match])
        teams = normalizeData(teamList, teams)
        const playerList = getPlayersFromTeams(teamList)
        players = normalizeData(playerList, players)

        //Switching Team for NBA and Preparing Cricket Match
        match = switchHomeAway.inMatch(match)
        if (match.sport === 'cricket') {
          match = prepareCricketMatch(match, teams.byId)
        }
        const { customId } = match
        if (allIds.indexOf(customId) === -1) {
          allIds.push(customId)
          byId[customId] = match
        } else {
          byId[customId] = { ...byId[customId], ...match }
        }
        matches = { ...matches, byId, allIds }
        return { matches, teams, players }
      })
      return this
    },
    updateMatch(match, shouldUpdateStore) {
      mergeWith(({ matches, teams, players }) => {
        if (shouldUpdateStore) {
          let byId = matches && matches.byId ? matches.byId : {}
          const { customId } = match
          match = { ...byId[customId], ...match }
          if (match.sport === 'cricket') match = prepareCricketMatch(match, teams.byId)
          byId[customId] = { ...match }
          matches = { ...matches, byId }
          return { matches }
        }
      })
      return this
    },
    loadMatches(matchList) {
      mergeWith(({ matches, teams, venues }) => {
        if (!matchList) return {}
        const teamList = getTeamsFromMatches(matchList)
        teams = normalizeData(teamList, teams)
        //Switching Team for NBA and Preparing Cricket Match
        for (let index in matchList) {
          matchList[index] = switchHomeAway.inMatch(matchList[index])
          const bad = matchList[index].cricketType === 'test' && matchList[index].inningScore.length < 4 && matchList[index].status === 'closed'
          // console.log('>', matchList[index].customId, bad, matchList[index].status, matchList[index].cricketType, matchList[index].inningScore.length)
          if (matchList[index].sport === 'cricket' && !bad) {
            matchList[index] = prepareCricketMatch(matchList[index], teams.byId)
          }
        }
        matches = normalizeData(matchList, matches)
        const venueList = getVenuesFromMatches(matchList)
        venues = normalizeData(venueList, venues)
        return { matches, teams, venues }
      })
      return this
    },
    updateMatchesViaSocket(facts) {
      mergeWith(({ matches, teams }) => {
        if (matches && matches.byId) {
          let matchesById = matches.byId
          matches = { ...matches, byId: Parser(matchesById, facts, teams) }
        }
        return { matches }
      })
      return this
    },
    updateMatchScoreViaSocket(matchCId, score) {
      mergeWith(({ matches }) => {
        if (matches && matches.byId) {
          let matchesById = matches.byId
          let match = matchesById[matchCId]
          let newScore = switchHomeAway.inMatch({ ...match, ...score, teamsSwitched: false })
          const { homeScore, awayScore } = newScore
          matchesById[matchCId] = { ...match, homeScore, awayScore }
          matches = { ...matches, matchesById }
        }
        return { matches }
      })
      return this
    },
    updateCricketStatusesViaSocket(cricketStatuses) {
      mergeWith(({ matches }) => {
        if (matches && matches.byId) {
          let matchesById = matches.byId
          const { status, matchCId, periodStatus, statusLong, statusShort, statusChannel, statusMytribe, inningScore, currentInning, currentSession, currentDay, matchStatus } =
            cricketStatuses
          const statuses = {
            periodStatus,
            status,
            matchStatusLong: statusLong,
            matchStatusShort: statusShort,
            matchStatusChannel: statusChannel,
            matchStatusMytribe: statusMytribe,
            inningScore,
            matchStatus,
            currentInning,
            currentSession,
            currentDay,
          }
          matchesById[matchCId] = { ...matchesById[matchCId], ...statuses }
          matches = { ...matches, byId: matchesById }
        }
        return { matches }
      })
      return this
    },
    updateMatchDataViaSocket(match) {
      mergeWith(({ matches }) => {
        if (matches && matches.byId) {
          let matchesById = matches.byId
          const { customId } = match
          matchesById[customId] = { ...matchesById[customId], ...match }
          matches = { ...matches, byId: matchesById }
        }
        return { matches }
      })
      return this
    },
    loadParserMatches(matches) {
      mergeWith(({ parserMatches }) => {
        if (!parserMatches) parserMatches = {}
        matches.map((m) => {
          parserMatches[m.customId] = m
        })
        return { parserMatches }
      })
      return this
    },
  }
}
