import normalizeData from '../../helpers/normalizeData'

export default mergeWith => {
  return {
    loadPartners(partnerList) {
      mergeWith(({ partners }) => {
        partners = normalizeData(partnerList)
        return { partners }
      })
      return this
    },
    createPartner(createPartner) {
      mergeWith(({ partners }) => {
        if (!partners) {
          partners = { allIds: [], byId: {} }
        }
        let byId = partners && partners.byId ? partners.byId : {}
        const { customId } = createPartner
        partners.allIds.push(customId)
        byId[customId] = { ...byId[customId], ...createPartner }
        partners = { ...partners, byId }
        return { partners }
      })
      return this
    },
    updatePartner(partner) {
      mergeWith(({ partners }) => {
        let byId = partners && partners.byId ? partners.byId : {}
        const { customId } = partner
        byId[customId] = { ...byId[customId], ...partner }
        partners = { ...partners, byId }
        return { partners }
      })
      return this
    }
  }
}
