import createPromisifiedFacade from 'facade-tools/lib/createPromisifiedFacade'
import * as Sentry from '@sentry/react'

const initialState = {
  adminUser: null
}

const AdminUserFacade = createPromisifiedFacade((mergeWith) => {
  return {
    loadAdminUser(adminUser) {
      mergeWith(() => {
        const adminUserDetail = { ...adminUser }
        const username = (adminUserDetail.firstName || '') + ' ' + (adminUserDetail.lastName || '')
        Sentry.setUser({
          email: adminUserDetail.email,
          username
        })
        return { adminUser: adminUserDetail }
      })
      return this
    },
    updateMatch({ customId, scheduled, videoId, isFromIntro }) {
      mergeWith((adminUser) => {
        if (isFromIntro) {
          const adminUserDetail = { ...adminUser.adminUser, commentaryMatchId: customId, commentaryStartedOn: scheduled, videoTimer: 0.0, videoId: videoId, isFromIntro: true }
          return { ...adminUser, adminUser: adminUserDetail }
        }
      })
      return this
    },
    updateIntroStatus(isFromIntro) {
      mergeWith((adminUser) => {
        const adminUserDetail = { ...adminUser.adminUser, isFromIntro: adminUser.adminUser.isFromIntro || isFromIntro ? true : false }
        return { ...adminUser, adminUser: adminUserDetail }
      })
      return this
    },
    updateVideoTimer(videoTimer) {
      mergeWith((adminUser) => {
        const adminUserDetail = { ...adminUser.adminUser, videoTimer }
        return { ...adminUser, adminUser: adminUserDetail, isFromIntro: false }
      })
      return this
    }
  }
}, initialState)

export default AdminUserFacade
