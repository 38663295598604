import React from 'react'
import { connect } from 'react-redux-hoc'
import { compose, pure, withHandlers } from 'recompose'
import withDispatchOnUpdate from '@surglogs/with-dispatch-on-update'
import { Row, Col } from 'reactstrap'

/*Components*/
import pickProps from '../components/hoc/pickProps'

/*Actions*/
import { setUrl } from '../actions/sidebar'
import { goTo } from '../actions/router'
import { loadAdminUser } from '../actions/adminUser'

/*Selectors*/
import { getAdminUser, shouldLoadAdminUser } from '../selectors/getAdminUser'
import { getActiveUrl } from '../selectors/sidebar'

/*Values*/
import menuValues from '../values/menu'

/*Config*/
import config from '../config'

/*Styles*/
import SidebarStyles from './styles/SidebarStyles'

const Sidebar = ({ adminUser, children, activeUrl, redirectToPath, path, setSidebarCollapsed, sidebarCollapsed }) => {
  let key = 0
  if (adminUser && adminUser.role) {
    let sidebarContent = [
      <li
        {...{
          className: '',
          key: 'hide',
          onClick: () => {
            setSidebarCollapsed(!sidebarCollapsed)
          }
        }}
      >
        <a href="#" style={{ fontSize: '26px' }}>
          {!sidebarCollapsed ? <span>&#171;</span> : <span>&#187;</span>}
        </a>
      </li>
    ]
    for (let section in menuValues) {
      for (let menu of menuValues[section]) {
        if (menu.role.indexOf(adminUser.role) !== -1 || menu.role.indexOf('all') !== -1) {
          let activeClass =
            activeUrl && activeUrl === menu.url && !menu.external ? 'active-link' : menu.slug && menu.slug.indexOf(path) !== -1 && !menu.external ? 'active-link' : 'inactive-link'
          let menuUrl = menu.external ? config.oldAdminUrl + menu.url : menu.url
          key++
          sidebarContent.push(
            <li {...{ className: activeClass, key: menu.url + key }}>
              <a
                href={menuUrl}
                {...{
                  onClick: redirectToPath({ menu, menuUrl })
                }}
              >
                {menu.name}
              </a>
            </li>
          )
        }
      }
      if (section !== menuValues.length - 1) sidebarContent.push(<hr key={section} className="menu-seperator" />)
    }

    const sidebar = (
      <Row className={SidebarStyles}>
        <Col
          className={'sidebar ' + (sidebarCollapsed ? 'collapsed' : '')}
          key="1"
          onClick={() => {
            if (sidebarCollapsed) setSidebarCollapsed(false)
          }}
        >
          <div>
            <ul className="nav">{!sidebarCollapsed ? sidebarContent : sidebarContent[0]}</ul>
          </div>
        </Col>

        <div className={'build-info ' + (sidebarCollapsed ? 'collapsed' : '')}>
          {!sidebarCollapsed && (
            <>
              {adminUser.firstName} {adminUser.lastName}
              <br />
              Build {config.buildNumber}
            </>
          )}
        </div>

        <Col className={'sidebar-margin ' + (sidebarCollapsed ? 'collapsed' : '')} md={{ size: 10 }} lg={{ size: 10 }} key="2">
          {children}
        </Col>
      </Row>
    )
    return sidebar
  }
  return null
}

export default compose(
  connect({
    adminUser: getAdminUser,
    activeUrl: getActiveUrl
  }),
  withDispatchOnUpdate({
    action: loadAdminUser,
    condition: (state) => {
      return shouldLoadAdminUser(state)
    }
  }),
  withHandlers({
    goToUrl:
      ({ dispatch }) =>
      ({ menuUrl }) => {
        dispatch(setUrl(menuUrl))
        dispatch(goTo(menuUrl))
      }
  }),
  withHandlers(() => {
    return {
      redirectToPath:
        ({ goToUrl }) =>
        ({ menu, menuUrl }) =>
        (e) => {
          if (!menu.external) {
            e.preventDefault()
            if (menuUrl) goToUrl({ menuUrl })
          }
        }
    }
  }),
  pickProps(['path', 'children', 'adminUser', 'redirectToPath', 'activeUrl', 'setSidebarCollapsed', 'sidebarCollapsed']),
  pure
)(Sidebar)
