import { createFacade } from 'facade-tools'

const initialState = {
  updateFound: false
}

const serviceWorker = createFacade(mergeWith => {
  return {
    updateFound() {
      mergeWith({ updateFound: true })
      return this
    }
  }
}, initialState)

export default serviceWorker
