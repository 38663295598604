const getTeamsFromMatches = matches => {
  let teams = []
  matches.map(match => {
    if (match.away) {
      teams.push(match.away)
    }
    if (match.home) {
      teams.push(match.home)
    }
    return match
  })
  return teams
}

export default getTeamsFromMatches