import namespacedSelector from '../helpers/selectors/namespacedSelector'
import shouldLoadListSelector from '../helpers/selectors/shouldLoadListSelector'
import getSelector from '../helpers/selectors/getSelector'

const selector = namespacedSelector('adminUser')

export const getAdminUserSelector = selector('adminUser')

export const getAdminUser = getSelector(getAdminUserSelector)

export const isAdminUserLoading = selector('pending')
export const shouldLoadAdminUser = shouldLoadListSelector(getAdminUser, isAdminUserLoading)
