import React from 'react'
import { Alert } from 'reactstrap'
import UpdateFoundStyles from './styles/UpdateFoundStyles'

const UpdateFound = ({ updateFound }) => {
  return updateFound ? (
    <div className={UpdateFoundStyles}>
      <Alert color="dark">
        <p>
          A new version of Admin is available.
          <span
            className="link"
            {...{
              onClick: (e) => {
                window.location.reload()
              }
            }}
          >
            Refresh
          </span>
        </p>
      </Alert>
    </div>
  ) : null
}

export default UpdateFound
