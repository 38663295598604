const DefaultTheme = {
  fontSize: 14,
  table: {
    fontSize: 14,
    thead: {
      backgroundColor: '#3a97dc',
      color: 'white'
    }
  }
}

export default DefaultTheme
