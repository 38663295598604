import typeToReducer from 'type-to-reducer'
import { PENDING, REJECTED, FULFILLED } from '../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../constants/ActionType'
import VenueFacade from '../facades/venue'

export default typeToReducer(
  {
    [ActionTypes.LOAD_VENUE]: {
      [PENDING](state, { payload }) {
        return VenueFacade(state)
          .startLoading()
          .get()
      },
      [FULFILLED](state) {
        return VenueFacade(state)
          .allVenuesLoaded()
          .finishLoading()
          .get()
      },
      [REJECTED](state, { payload }) {
        return VenueFacade(state)
          .allVenuesLoaded()
          .finishLoading()
          .get()
      }
    }
  },
  VenueFacade().get()
)
