const normalizeData = (dataArray, intialData, key = 'customId') => {
  let result = intialData || { allIds: [], byId: {} }
  if (dataArray && dataArray.length > 0) {
    dataArray.map((data) => {
      if (result.allIds.indexOf(data[key]) === -1) {
        result.allIds.push(data[key])
      }
      result.byId[data[key]] = { ...result.byId[data[key]], ...data }
      return data
    })
  }
  return { ...result }
}

export default normalizeData
