import normalizeData from '../../helpers/normalizeData'
import updateNormalizedData from '../../helpers/updateNormalizedData'
import createNormalizedData from '../../helpers/createNormalizedData'

export default (mergeWith) => {
  return {
    loadQuizes(quizList) {
      mergeWith({ quizes: normalizeData(quizList, null, '_id') })
      return this
    },
    createQuiz(quiz) {
      mergeWith(({ quizes }) => {
        return { quizes: createNormalizedData(quiz, quizes, '_id') }
      })
      return this
    },
    updateQuiz(quiz) {
      mergeWith(({ quizes }) => {
        return { quizes: updateNormalizedData(quiz, quizes, '_id') }
      })
      return this
    }
  }
}
