import normalizeData from '../../helpers/normalizeData'

export default (mergeWith) => {
  return {
    loadVenues(venuesList) {
      mergeWith({ venues: normalizeData(venuesList) })
      return this
    }
  }
}
