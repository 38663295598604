export default [
  { title: 'Commentary', type: 'commentary' },
  { title: 'Down', type: 'down' },
  { title: 'Kickoff', type: 'kickoff', shouldUpdateMatch: true },
  { title: 'Extra Points', type: 'extra_points', shouldUpdateMatch: true },
  {
    title: 'Start Match',
    type: 'match_started',
    shouldUpdateMatch: true,
    matchUpdate: {
      status: 'inprogress',
      period: 'P1',
      periodStatus: 'period_started',
      adminUser: true
    }
  },
  {
    title: 'End Match',
    type: 'match_ended',
    shouldUpdateMatch: true,
    showScoreBoard: true,
    matchUpdate: {
      status: 'closed',
      periodStatus: 'period_over'
    }
  }
]
