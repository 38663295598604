import { connect } from 'react-redux'

import UpdateFound from '../UpdateFound'
import { updateFound } from '../../selectors/serviceWorker'

export default connect(
  (state, props) => {
    return {
      ...props,
      updateFound: updateFound(state)
    }
  },
  {},
  (props, actions) => {
    return {
      ...props
    }
  }
)(UpdateFound)
