import normalizeData from '../../helpers/normalizeData'

export default (mergeWith) => {
  return {
    loadAdditionalStatsData(statsData) {
      mergeWith(({ additionalStatsData }) => {
        additionalStatsData = normalizeData(statsData, null, 'tournamentGroupCId')
        return { additionalStatsData }
      })
      return this
    },
    resetAdditionalStats() {
      mergeWith({
        additionalStatsData: null,
      })
      return this
    },
  }
}
