import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_TEAM]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state)
        .startLoadingEntity('teams')
        .get()
    },
    [FULFILLED](state, { payload }) {
      const { getAllTeam } = payload
      return EntityFacade(state)
        .loadTeams(getAllTeam)
        .finishLoadingEntity('teams')
        .get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state)
        .loadTeams([])
        .finishLoadingEntity('teams')
        .get()
    }
  }
}
