import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_SETTINGS]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state)
        .startLoadingEntity('settings')
        .get()
    },
    [FULFILLED](state, { payload }) {
      let { settings } = payload
      return EntityFacade(state)
        .loadSettings(settings)
        .finishLoadingEntity('settings')
        .get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state)
        .loadSettings([])
        .finishLoadingEntity('settings')
        .get()
    }
  },
  [ActionTypes.UPDATE_SETTING]: {
    [FULFILLED](state, { meta }) {
      const { setting, value } = meta
      return EntityFacade(state)
        .updateSetting(setting, value)
        .get()
    }
  }
}
