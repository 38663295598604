import normalizeData from '../../helpers/normalizeData'

export default (mergeWith) => {
  return {
    loadSeason(seasonsList) {
      mergeWith(({ seasons }) => {
        seasons = { ...normalizeData(seasonsList), byLeagueId: {} }
        if (seasonsList && seasonsList.length > 0) {
          seasonsList.map((season) => {
            if (!season.hidden && !seasons.byLeagueId[season.leagueCId]) {
              seasons.byLeagueId[season.leagueCId] = {}
            }
            if (!season.hidden && !seasons.byLeagueId[season.leagueCId][season.season]) {
              seasons.byLeagueId[season.leagueCId][season.season] = season.customId
            }
            return season
          })
        }
        return { seasons }
      })
      return this
    }
  }
}
