import createPromisifiedFacade from 'facade-tools/lib/createPromisifiedFacade'

const initialState = {
  commentatorReport: null
}

const ReportFacade = createPromisifiedFacade(mergeWith => {
  return {
    commentatorReport({ matchWithCommentator }) {
      mergeWith(({ commentatorReport }) => {
        commentatorReport = matchWithCommentator
        return { commentatorReport }
      })
      return this
    }
  }
}, initialState)

export default ReportFacade
