import io from 'socket.io-client'
import config from '../config'
import socketEvents from '../values/socketEvents.js'
const socket = io(`${config.socket.url}`)
const init = (store) => {
  socket.open()

  socket.emit('partner.auth', {
    apiKey: `${config.socket.key}`
  })
  socket.on('disconnect', (reason) => {
    console.log('DISCONNECT!', reason)
    socket.connect()
    socket.emit('partner.auth', {
      apiKey: `${config.socket.key}`
    })
  })
  for (let event of socketEvents) {
    socket.on(event.event, (payload) => {
      store.dispatch({ type: event.reduxAction, payload })
    })
  }
}

export default init
