import typeToReducer from 'type-to-reducer'

import ServiceWorker from '../facades/serviceWorker'
import * as ActionTypes from '../constants/ActionType'

export default typeToReducer(
  {
    [ActionTypes.SERVICEWORKER_UPDATE_FOUND](state) {
      return ServiceWorker(state)
        .updateFound()
        .get()
    }
  },
  ServiceWorker().get()
)
