import typeToReducer from 'type-to-reducer'
import * as ActionTypes from '../constants/ActionType'
import QuizFacade from '../facades/quiz'

export default typeToReducer(
  {
    [ActionTypes.OPEN_QUIZ_POPUP]: (state, { payload }) => {
      return QuizFacade(state).openQuizPopup(payload).get()
    },
    [ActionTypes.CLOSE_QUIZ_POPUP]: (state) => {
      return QuizFacade(state).closeQuizPopup().get()
    },
    [ActionTypes.OPEN_QUESTION_POPUP]: (state, { payload }) => {
      return QuizFacade(state).openQuestionPopup(payload).get()
    },
    [ActionTypes.CLOSE_QUESTION_POPUP]: (state) => {
      return QuizFacade(state).closeQuestionPopup().get()
    },
    [ActionTypes.OPEN_ROUND_POPUP]: (state, { payload }) => {
      return QuizFacade(state).openRoundPopup(payload).get()
    },
    [ActionTypes.CLOSE_ROUND_POPUP]: (state) => {
      return QuizFacade(state).closeRoundPopup().get()
    },
    [ActionTypes.OPEN_INSTANCE_POPUP]: (state, { payload }) => {
      return QuizFacade(state).openInstancePopup(payload).get()
    },
    [ActionTypes.CLOSE_INSTANCE_POPUP]: (state) => {
      return QuizFacade(state).closeInstancePopup().get()
    },
    [ActionTypes.OPEN_ARCHIVE_POPUP]: (state, { payload }) => {
      return QuizFacade(state).openArchivePopup(payload).get()
    },
    [ActionTypes.CLOSE_ARCHIVE_POPUP]: (state) => {
      return QuizFacade(state).closeArchivePopup().get()
    }
  },
  QuizFacade().get()
)
