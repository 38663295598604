import typeToReducer from 'type-to-reducer'
import { PENDING, REJECTED, FULFILLED } from '../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../constants/ActionType'
import SeasonFacade from '../facades/season'

export default typeToReducer(
  {
    [ActionTypes.LOAD_MATCHES_BY_SEASON]: {
      [PENDING](state, { payload }) {
        return SeasonFacade(state)
          .startLoading()
          .get()
      },
      [FULFILLED](state, { meta, payload }) {
        const { seasonCIds } = meta
        const { loadMatchesBySeason } = payload
        return SeasonFacade(state)
          .loadMatches(loadMatchesBySeason, seasonCIds)
          .finishLoading()
          .get()
      }
    },
    [ActionTypes.CREATE_MATCH]: {
      [FULFILLED](state, { meta, payload }) {
        const { createMatch } = payload
        const { seasonCId } = createMatch
        return SeasonFacade(state)
          .createMatch(createMatch, seasonCId)
          .finishLoading()
          .get()
      }
    },
    [ActionTypes.LOAD_SEASON]: {
      [PENDING](state, { payload }) {
        return SeasonFacade(state)
          .startLoading()
          .get()
      },
      [FULFILLED](state) {
        return SeasonFacade(state)
          .allSeasonsLoaded()
          .finishLoading()
          .get()
      },
      [REJECTED](state, { payload }) {
        return SeasonFacade(state)
          .allSeasonsLoaded()
          .finishLoading()
          .get()
      }
    }
  },
  SeasonFacade().get()
)
