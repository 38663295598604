import { FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_MATCHES_BY_DATE]: {
    [FULFILLED](state, { meta, payload }) {
      const { loadMatchesByDate, loadMatchesByAdminUser } = payload
      return EntityFacade(state)
        .loadMatches(loadMatchesByDate || loadMatchesByAdminUser)
        .get()
    }
  },
  [ActionTypes.LOAD_MATCHES_BY_ADMIN_USER]: {
    [FULFILLED](state, { meta, payload }) {
      const { loadMatchesByAdminUser } = payload
      return EntityFacade(state).loadMatches(loadMatchesByAdminUser).get()
    }
  },
  [ActionTypes.LOAD_MATCHES_BY_SEASON]: {
    [FULFILLED](state, { meta, payload }) {
      const { loadMatchesBySeason } = payload
      return EntityFacade(state).loadMatches(loadMatchesBySeason).get()
    }
  },
  [ActionTypes.LOAD_MATCHES_BY_CUSTOMIDS]: {
    [FULFILLED](state, { meta, payload }) {
      const { loadMatchesByCustomIds } = payload
      return EntityFacade(state).loadMatches(loadMatchesByCustomIds).get()
    }
  },
  [ActionTypes.LOAD_MATCHES_BY_CHANNELIDS]: {
    [FULFILLED](state, { meta, payload }) {
      const { loadMatchesByChannelIds } = payload
      return EntityFacade(state).loadMatches(loadMatchesByChannelIds).get()
    }
  },
  [ActionTypes.LOAD_MATCHES_WITH_ADDITIONAL_DATA]: {
    [FULFILLED](state, { meta, payload }) {
      const { loadMatchesWithAdditionalData } = payload
      return EntityFacade(state).loadMatches(loadMatchesWithAdditionalData).get()
    }
  },
  [ActionTypes.LOAD_PARSER_MATCHES]: {
    [FULFILLED](state, { meta, payload }) {
      const { liveMatches } = payload.data
      return EntityFacade(state).loadParserMatches(liveMatches).get()
    }
  },

  [ActionTypes.CREATE_MATCH]: {
    [FULFILLED](state, { payload }) {
      const { createMatch } = payload
      return EntityFacade(state).loadMatch(createMatch).get()
    }
  },
  [ActionTypes.UPDATE_MATCH]: {
    [FULFILLED](state, { meta }) {
      const { match, shouldUpdateStore } = meta
      return EntityFacade(state).updateMatch(match, shouldUpdateStore).get()
    }
  },
  [ActionTypes.LOAD_MATCH]: {
    [FULFILLED](state, { payload }) {
      const { match } = payload
      return EntityFacade(state).loadMatch(match).get()
    }
  },
  [ActionTypes.LOAD_BROADCAST_PROGRAM_MATCHES]: {
    [FULFILLED](state, { meta, payload }) {
      const { loadMatchesByCustomIds } = payload
      return EntityFacade(state).loadMatches(loadMatchesByCustomIds).get()
    }
  }
}
