import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_SEASON]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state)
        .startLoadingEntity('seasons')
        .get()
    },
    [FULFILLED](state, { payload }) {
      const { season } = payload
      return EntityFacade(state)
        .loadSeason(season)
        .finishLoadingEntity('seasons')
        .get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state)
        .loadSeason([])
        .finishLoadingEntity('seasons')
        .get()
    }
  }
}
