import normalizeData from '../../helpers/normalizeData'
import updateNormalizedData from '../../helpers/updateNormalizedData'
import createNormalizedData from '../../helpers/createNormalizedData'
import removeNormalizedData from '../../helpers/removeNormalizedData'

export default (mergeWith) => {
  return {
    loadQuizQuestions(questions) {
      mergeWith({ quizQuestions: normalizeData(questions, null, '_id') })
      return this
    },
    createQuizQuestion(question) {
      mergeWith(({ quizQuestions }) => {
        return { quizQuestions: createNormalizedData(question, quizQuestions, '_id') }
      })
      return this
    },
    updateQuizQuestion(question) {
      mergeWith(({ quizQuestions }) => {
        return { quizQuestions: updateNormalizedData(question, quizQuestions, '_id') }
      })
      return this
    },
    deleteQuizQuestion(question) {
      mergeWith(({ quizQuestions }) => {
        return { quizQuestions: removeNormalizedData(question, quizQuestions, '_id') }
      })
      return this
    }
  }
}
