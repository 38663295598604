const parseCommon = (match, fact) => {
  switch (fact.type) {
    case 'match_started':
      match.status = 'inprogress'
      break
    case 'period_started':
      match.period = fact.period
      match.periodStatus = 'period_started'
      match.periodTime = fact.time
      break
    case 'period_over':
      match.period = fact.period
      match.periodStatus = 'period_over'
      match.periodTime = fact.time
      break
    case 'match_ended':
      match.status = 'closed'
      match.homeScore = fact.homeScore
      match.awayScore = fact.awayScore
      break
    default:
      break
  }
  if (fact.homeScore > match.homeScore || fact.awayScore > match.awayScore) {
    match.homeScore = fact.homeScore
    match.awayScore = fact.awayScore
  }
  console.log('[Parser]', fact.time, fact.type, fact)
  return match
}
export default parseCommon
