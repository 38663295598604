import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_QUIZES]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state).startLoadingEntity('quizes').get()
    },
    [FULFILLED](state, { payload }) {
      const { quizes } = payload
      return EntityFacade(state).loadQuizes(quizes).finishLoadingEntity('quizes').get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state).loadQuizes([]).finishLoadingEntity('quizes').get()
    }
  },
  [ActionTypes.CREATE_QUIZ]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state).startLoadingEntity('quizes').get()
    },
    [FULFILLED](state, { payload }) {
      const { createQuiz } = payload
      return EntityFacade(state).createQuiz(createQuiz).finishLoadingEntity('quizes').get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state).createQuiz(null).finishLoadingEntity('quizes').get()
    }
  },
  [ActionTypes.UPDATE_QUIZ]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state).startLoadingEntity('quizes').get()
    },
    [FULFILLED](state, { payload }) {
      const { updateQuiz } = payload
      return EntityFacade(state).updateQuiz(updateQuiz).finishLoadingEntity('quizes').get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state).updateQuiz(null).finishLoadingEntity('quizes').get()
    }
  }
}
