import normalizeData from '../../helpers/normalizeData'
import updateNormalizedData from '../../helpers/updateNormalizedData'
import createNormalizedData from '../../helpers/createNormalizedData'
import removeNormalizedData from '../../helpers/removeNormalizedData'

export default (mergeWith) => {
  return {
    loadPredictionQuestions(questions) {
      mergeWith({ predictionQuestions: normalizeData(questions, null, '_id') })
      return this
    },
    createPredictionQuestion(question) {
      mergeWith(({ predictionQuestions }) => {
        return { predictionQuestions: createNormalizedData(question, predictionQuestions, '_id') }
      })
      return this
    },
    updatePredictionQuestion(question) {
      mergeWith(({ predictionQuestions }) => {
        return { predictionQuestions: updateNormalizedData(question, predictionQuestions, '_id') }
      })
      return this
    },
    deletePredictionQuestion(question) {
      mergeWith(({ predictionQuestions }) => {
        return { predictionQuestions: removeNormalizedData(question, predictionQuestions, '_id') }
      })
      return this
    }
  }
}
