import typeToReducer from 'type-to-reducer'
import { PENDING, REJECTED, FULFILLED } from '../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../constants/ActionType'
import AdminUserFacade from '../facades/adminUser'

export default typeToReducer(
  {
    [ActionTypes.LOAD_ADMIN_USER]: {
      [PENDING](state, { payload }) {
        return AdminUserFacade(state)
          .startLoading()
          .get()
      },
      [FULFILLED](state, { payload }) {
        const { adminUser } = payload
        return AdminUserFacade(state)
          .loadAdminUser(adminUser)
          .finishLoading()
          .get()
      },
      [REJECTED](state, { payload }) {
        return AdminUserFacade(state)
          .loadAdminUser([])
          .finishLoading()
          .get()
      }
    },
    // This is for trail commentators
    [ActionTypes.CREATE_MATCH]: {
      [PENDING](state, { payload }) {
        return AdminUserFacade(state)
          .startLoading()
          .get()
      },
      [FULFILLED](state, { payload, meta }) {
        const { createMatch } = payload
        const { videoId, isFromIntro } = meta
        return AdminUserFacade(state)
          .updateMatch({ ...createMatch, isFromIntro, videoId })
          .finishLoading()
          .get()
      },
      [REJECTED](state, { payload }) {
        return AdminUserFacade(state)
          .finishLoading()
          .get()
      }
    },
    [ActionTypes.UPDATE_VIDEO_TIMER]: {
      [PENDING](state, { payload }) {
        return AdminUserFacade(state)
          .startLoading()
          .get()
      },
      [FULFILLED](state, { meta }) {
        const { videoTimer } = meta
        return AdminUserFacade(state)
          .updateVideoTimer(videoTimer)
          .finishLoading()
          .get()
      },
      [REJECTED](state, { payload }) {
        return AdminUserFacade(state)
          .finishLoading()
          .get()
      }
    },
    [ActionTypes.LOAD_MATCH]: {
      [FULFILLED](state, { meta }) {
        const { isFromIntro } = meta
        return AdminUserFacade(state)
          .updateIntroStatus(isFromIntro)
          .finishLoading()
          .get()
      }
    }
  },
  AdminUserFacade().get()
)
