import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_PARTNERS]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state)
        .startLoadingEntity('partners')
        .get()
    },
    [FULFILLED](state, { payload }) {
      const { partners } = payload
      return EntityFacade(state)
        .loadPartners(partners)
        .finishLoadingEntity('partners')
        .get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state)
        .loadPartners([])
        .finishLoadingEntity('partners')
        .get()
    }
  },
  [ActionTypes.CREATE_PARTNER]: {
    [FULFILLED](state, { payload }) {
      const { createPartner } = payload
      return EntityFacade(state)
        .createPartner(createPartner)
        .get()
    }
  },
  [ActionTypes.UPDATE_PARTNER]: {
    [FULFILLED](state, { meta }) {
      const { partner } = meta
      return EntityFacade(state)
        .updatePartner(partner)
        .get()
    }
  }
}
