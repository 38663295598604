import { connect } from 'react-redux'

import PrivateRoute from '../PrivateRoute'
import { isAccessTokenValid } from '../../selectors/token'
import { getAdminUser } from '../../selectors/getAdminUser'
import loadAdminUserIfNeeded from '../../connectors/adminUser/loadAdminUserIfNeeded'
import { loadAdminUser } from '../../actions/adminUser'
import redirectUserBasedOnRole from '../../connectors/adminUser/redirectUserBasedOnRole'
import { goToDefaultMatchList, goToIntro, goToQuizzes } from '../../actions/router'

export default connect(
  (state, props) => {
    return {
      ...props,
      isAuthenticated: isAccessTokenValid(state),
      adminUser: getAdminUser(state)
    }
  },
  { loadAdminUser, goToIntro, goToDefaultMatchList, goToQuizzes },
  (props, actions) => {
    return {
      ...props,
      loadAdminUserIfNeeded: loadAdminUserIfNeeded(props, actions),
      redirectUserBasedOnRole: redirectUserBasedOnRole(props, actions)
    }
  }
)(PrivateRoute)
