import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import './client/index.css'
import App from './client/App'
import config from './client/config'

/*Registering Service Worker*/
import store from './client/store'
import registerServiceWorker from './client/registerServiceWorker'
import { serviceworkerUpdateFound } from './client/actions/serviceWorker'

//Init Sentry

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: config.sentry.dsn, tracesSampleRate: config.sentry.tracesSampleRate, integrations: [new Integrations.BrowserTracing()] })
}

/*End Registering Service Worker*/

ReactDOM.render(<App />, document.getElementById('root'))

/*Registering Service Worker*/
const onUpdateFound = () => {
  store.dispatch(serviceworkerUpdateFound())
}
registerServiceWorker(onUpdateFound)
/*End Registering Service Worker*/
