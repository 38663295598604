import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_ORGANIZATIONS]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state).startLoadingEntity('organizations').get()
    },
    [FULFILLED](state, { payload }) {
      const { organizations } = payload
      return EntityFacade(state).loadOrganizations(organizations).finishLoadingEntity('organizations').get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state).loadOrganizations([]).finishLoadingEntity('organizations').get()
    }
  }
}
