import { createFacade } from 'facade-tools'
import AccessToken from '../lib/AccessToken'

const initialState = {
  accessToken: AccessToken.get()
}

const TokenFacade = createFacade((mergeWith) => {
  return {
    removeAccessToken() {
      mergeWith({ accessToken: null })
      AccessToken.clear()
      return this
    },
    setAccessToken(accessToken) {
      mergeWith({ accessToken })
      return this
    }
  }
}, initialState)

export default TokenFacade
