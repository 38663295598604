import { shouldLoadAdminUser } from '../../selectors/getAdminUser'
import store from '../../store'

const loadAdminUserIfNeeded = (props, { loadAdminUser }) => {
  return () => {
    if (shouldLoadAdminUser(store.getState())) {
      return loadAdminUser()
    }
  }
}

export default loadAdminUserIfNeeded
