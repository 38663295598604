import { isAccessTokenValid } from '../../selectors/token'
import { removeToken } from '../../actions/token'

export default () => {
  return (store) => (next) => (action) => {
    if (action && action.error) {
      const { payload = {} } = action
      const { graphQLErrors = [] } = payload
      const { status } = graphQLErrors.shift() || {}

      if (+status === 401) {
        if (isAccessTokenValid(store.getState())) {
          next(removeToken())
        }
      }
    }

    return next(action)
  }
}
