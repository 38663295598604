export const CREATE_FACT = 'CREATE_FACT'
export const CREATE_FACTS = 'CREATE_FACTS'
export const LOAD_FACTS = 'LOAD_FACTS'
export const LOAD_MATCH_FACTS = 'LOAD_MATCH_FACTS'
export const LOAD_MATCH = 'LOAD_MATCH'
export const UPDATE_MATCH = 'UPDATE_MATCH'
export const LOAD_ADMIN_USER = 'LOAD_ADMIN_USER'
export const SET_TOKEN = 'SET_TOKEN'
export const REMOVE_TOKEN = 'REMOVE_TOKEN'
export const LOAD_FACT_TYPE_MATCH = 'LOAD_FACT_TYPE_MATCH'
export const UPDATE_VIDEO_TIMER = 'UPDATE_VIDEO_TIMER'
export const UPDATE_FACT = 'UPDATE_FACT'
export const UPDATE_FACTS = 'UPDATE_FACTS'
export const CREATE_MATCH = 'CREATE_MATCH'
export const CLEAR_MATCH_NEW_FACT = 'CLEAR_MATCH_NEW_FACT'
export const CLEAR_MATCH_UPDATE_FACT = 'CLEAR_MATCH_UPDATE_FACT'
export const LOAD_LINEUP = 'LOAD_LINEUP'
export const OPEN_LINEUP_EDIT_POPUP = 'OPEN_LINEUP_EDIT_POPUP'
export const CLOSE_LINEUP_EDIT_POPUP = 'CLOSE_LINEUP_EDIT_POPUP'
export const SAVE_LINEUP = 'SAVE_LINEUP'
export const LOAD_LEAGUE = 'LOAD_LEAGUE'
export const LOAD_SEASON = 'LOAD_SEASON'
export const LOAD_VENUE = 'LOAD_VENUE'
export const LOAD_MATCHES_BY_SEASON = 'LOAD_MATCHES_BY_SEASON'
export const LOAD_ADMIN_USERS = 'LOAD_ADMIN_USERS'
export const LOAD_MATCHES_BY_DATE = 'LOAD_MATCHES_BY_DATE'
export const LOAD_MATCHES_BY_ADMIN_USER = 'LOAD_MATCHES_BY_ADMIN_USER'
export const RESET_ALL_MATCHES = 'RESET_ALL_MATCHES'
export const SET_MATCH_AVAILABILITY = 'SET_MATCH_AVAILABILITY'
export const UPDATE_ENTITY_BY_ID = 'UPDATE_ENTITY_BY_ID'
export const UPDATE_ENTITY_STORE_BY_ID = 'UPDATE_ENTITY_STORE_BY_ID'
export const CREATE_ENTITY = 'CREATE_ENTITY'
export const LOAD_ENTITY_BY_ID = 'LOAD_ENTITY_BY_ID'
export const SKIP_COMMENTARY_INTRO = 'SKIP_COMMENTARY_INTRO'
export const SOCKET_FACTS_CREATED = 'SOCKET_FACTS_CREATED'
export const OPEN_MATCH_EDIT_POPUP = 'OPEN_MATCH_EDIT_POPUP'
export const CLOSE_MATCH_EDIT_POPUP = 'CLOSE_MATCH_EDIT_POPUP'
export const LOAD_TEAM = 'LOAD_TEAM'
export const OPEN_MATCH_ADD_POPUP = 'OPEN_MATCH_ADD_POPUP'
export const CLOSE_ADD_MATCH_POPUP = 'CLOSE_ADD_MATCH_POPUP'
export const ADD_ENTITY_TO_STORE = 'ADD_ENTITY_TO_STORE'
export const FINISH_LOADER = 'FINISH_LOADER'
export const START_LOADER = 'START_LOADER'
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION'
export const OPEN_SCORE_EDIT_POPUP = 'OPEN_SCORE_EDIT_POPUP'
export const CLOSE_SCORE_EDIT_POPUP = 'CLOSE_SCORE_EDIT_POPUP'
export const SET_SIDEBAR = 'SET_SIDEBAR'
export const SET_IS_FACT_REARRAGING = 'SET_IS_FACT_REARRAGING'
export const OPEN_FINAL_SCORE_EDIT_POPUP = 'OPEN_FINAL_SCORE_EDIT_POPUP'
export const CLOSE_FINAL_SCORE_EDIT_POPUP = 'CLOSE_FINAL_SCORE_EDIT_POPUP'
export const CREATE_UPDATE_FACTS = 'CREATE_UPDATE_FACTS'
export const OPEN_MATCH_LIFE_CYCLE_POPUP = 'OPEN_MATCH_LIFE_CYCLE_POPUP'
export const CLOSE_MATCH_LIFE_CYCLE_POPUP = 'CLOSE_MATCH_LIFE_CYCLE_POPUP'
export const CLOSE_LIVE_SCORE_POPUP = 'CLOSE_LIVE_SCORE_POPUP'
export const OPEN_LIVE_SCORE_POPUP = 'OPEN_LIVE_SCORE_POPUP'
export const SOCKET_SCORE_UPDATED = 'SOCKET_SCORE_UPDATED'
export const LOG_IN_PARTNER = 'LOG_IN_PARTNER'
export const LOAD_PARTNERS = 'LOAD_PARTNERS'
export const OPEN_ADD_PARTNER_POPUP = 'OPEN_ADD_PARTNER_POPUP'
export const CLOSE_ADD_PARTNER_POPUP = 'CLOSE_ADD_PARTNER_POPUP'
export const CREATE_PARTNER = 'CREATE_PARTNER'
export const OPEN_EDIT_PARTNER_POPUP = 'OPEN_EDIT_PARTNER_POPUP'
export const CLOSE_EDIT_PARTNER_POPUP = 'CLOSE_EDIT_PARTNER_POPUP'
export const RESET_CP_MATCHES = 'RESET_CP_MATCHES'
export const RESET_ADDITIONAL_STATS = 'RESET_ADDITIONAL_STATS'
export const RESET_ADDITIONAL_TABLE = 'RESET_ADDITIONAL_TABLE'
export const UPDATE_PARTNER = 'UPDATE_PARTNER'
export const SAVE_MATCH_NOTE = 'SAVE_MATCH_NOTE'
export const SERVICEWORKER_UPDATE_FOUND = 'SERVICEWORKER_UPDATE_FOUND'
export const LOAD_COMMENTATOR_REPORT = 'LOAD_COMMENTATOR_REPORT'
export const SAVE_TV_NOTE = 'SAVE_TV_NOTE'
export const CUT_MATCH_FEED = 'CUT_MATCH_FEED'
export const OPEN_MATCH_STATUS_EDIT_POPUP = 'OPEN_MATCH_STATUS_EDIT_POPUP'
export const CLOSE_MATCH_STATUS_EDIT_POPUP = 'CLOSE_MATCH_STATUS_EDIT_POPUP'
export const GET_MATCH_USAGE_FROM_MIXPANEL = 'GET_MATCH_USAGE_FROM_MIXPANEL'
export const GET_CHANNEL_USAGE_FROM_MIXPANEL = 'GET_CHANNEL_USAGE_FROM_MIXPANEL'
export const RESET_PARTNERS_DATA = 'RESET_PARTNERS_DATA'
export const LOAD_MATCHES_BY_CUSTOMIDS = 'LOAD_MATCHES_BY_CUSTOMIDS'
export const LOAD_MATCHES_BY_CHANNELIDS = 'LOAD_MATCHES_BY_CHANNELIDS'
export const LOAD_BROADCAST_PROVIDERS = 'LOAD_BROADCAST_PROVIDERS'
export const LOAD_BROADCAST_CHANNELS = 'LOAD_BROADCAST_CHANNELS'
export const OPEN_EDIT_PROVIDER_POPUP = 'OPEN_EDIT_PROVIDER_POPUP'
export const CLOSE_EDIT_PROVIDER_POPUP = 'CLOSE_EDIT_PROVIDER_POPUP'
export const UPDATE_BROADCAST_PROVIDER = 'UPDATE_BROADCAST_PROVIDER'
export const OPEN_ADD_PROVIDER_POPUP = 'OPEN_ADD_PROVIDER_POPUP'
export const CLOSE_ADD_PROVIDER_POPUP = 'CLOSE_ADD_PROVIDER_POPUP'
export const CREATE_BROADCAST_PROVIDER = 'CREATE_BROADCAST_PROVIDER'
export const LOAD_BROADCAST_PROGRAMMES = 'LOAD_BROADCAST_PROGRAMMES'
export const LOAD_BROADCAST_PROGRAM_MATCHES = 'LOAD_BROADCAST_PROGRAM_MATCHES'
export const LOAD_SETTINGS = 'LOAD_SETTINGS'
export const UPDATE_SETTING = 'UPDATE_SETTING'
export const LOAD_CRICKET_STATS = 'LOAD_CRICKET_STATS'
export const ADD_FACTS_TO_STORE = 'ADD_FACTS_TO_STORE'
export const REMOVE_FACT_FROM_STORE = 'REMOVE_FACT_FROM_STORE'
export const UPDATE_FACT_IN_STORE = 'UPDATE_FACT_IN_STORE'
export const ADD_CRICKET_DESCRIPTION = 'ADD_CRICKET_DESCRIPTION'
export const UPDATE_CRICKET_STATS = 'UPDATE_CRICKET_STATS'
export const SET_CRICKET_OVER_OPEN = 'SET_CRICKET_OVER_OPEN'
export const ENABLE_CRICKET_DESCRIPTION_TO_EDIT = 'ENABLE_CRICKET_DESCRIPTION_TO_EDIT'
export const DISABLE_CRICKET_DESCRIPTION_TO_EDIT = 'DISABLE_CRICKET_DESCRIPTION_TO_EDIT'
export const REMOVE_CRICKET_DESCRIPTION = 'REMOVE_CRICKET_DESCRIPTION'
export const SET_CRICKET_DESCRIPTION_HEIGHT = 'SET_CRICKET_DESCRIPTION_HEIGHT'
export const SET_ACTIVE_BALL_PANEL_ID = 'SET_ACTIVE_BALL_PANEL_ID'
export const ENABLE_SAVE_BUTTON = 'ENABLE_SAVE_BUTTON'
export const OPEN_EDIT_PLAYER_POPUP = 'OPEN_EDIT_PLAYER_POPUP'
export const ENABLE_BULK_EDIT_MODE = 'ENABLE_BULK_EDIT_MODE'
export const NAVIGATE_MATCH_TO_LIVE_PAGE = 'NAVIGATE_MATCH_TO_LIVE_PAGE'
export const REMOVE_NAVIGATED_MATCH_FROM_STORE = 'REMOVE_NAVIGATED_MATCH_FROM_STORE'
export const LOAD_QUIZES = 'LOAD_QUIZES'
export const CREATE_QUIZ = 'CREATE_QUIZ'
export const UPDATE_QUIZ = 'UPDATE_QUIZ'
export const LOAD_QUIZ_QUESTIONS = 'LOAD_QUIZ_QUESTIONS'
export const UPDATE_QUIZ_QUESTION = 'UPDATE_QUIZ_QUESTION'
export const DELETE_QUIZ_QUESTION = 'DELETE_QUIZ_QUESTION'
export const CREATE_QUIZ_QUESTION = 'CREATE_QUIZ_QUESTION'
export const OPEN_QUESTION_POPUP = 'OPEN_QUESTION_POPUP'
export const CLOSE_QUESTION_POPUP = 'CLOSE_QUESTION_POPUP'
export const OPEN_ROUND_POPUP = 'OPEN_ROUND_POPUP'
export const CLOSE_ROUND_POPUP = 'CLOSE_ROUND_POPUP'
export const OPEN_INSTANCE_POPUP = 'OPEN_INSTANCE_POPUP'
export const CLOSE_INSTANCE_POPUP = 'CLOSE_INSTANCE_POPUP'
export const OPEN_ARCHIVE_POPUP = 'OPEN_ARCHIVE_POPUP'
export const CLOSE_ARCHIVE_POPUP = 'CLOSE_ARCHIVE_POPUP'
export const OPEN_QUIZ_POPUP = 'OPEN_QUIZ_POPUP'
export const CLOSE_QUIZ_POPUP = 'CLOSE_QUIZ_POPUP'
export const LOAD_ORGANIZATIONS = 'LOAD_ORGANIZATIONS'
export const OPEN_ADD_PLAYER_POPUP = 'OPEN_ADD_PLAYER_POPUP'
export const CLOSE_ADD_PLAYER_POPUP = 'CLOSE_ADD_PLAYER_POPUP'
export const CREATE_PLAYER = 'CREATE_PLAYER'
export const SET_SCORE_RECALCULATION = 'SET_SCORE_RECALCULATION'
export const CLOSE_CRICKET_CONTROL_FORM = 'CLOSE_CRICKET_CONTROL_FORM'
export const ENABLE_CRICKET_DATA_EDIT_MODE = 'ENABLE_CRICKET_DATA_EDIT_MODE'
export const ENABLE_BREAK_SAVE_BUTTON = 'ENABLE_BREAK_SAVE_BUTTON'
export const SET_CURRENT_OVER_BALLS = 'SET_CURRENT_OVER_BALLS'
export const SOCKET_CRICKET_STATS_UPDATED = 'SOCKET_CRICKET_STATS_UPDATED'
export const UPDATE_MATCH_FROM_CONTROL_PANEL = 'UPDATE_MATCH_FROM_CONTROL_PANEL'
export const SOCKET_CRICKET_STATUSES_UPDATED = 'SOCKET_CRICKET_STATUSES_UPDATED'
export const SOCKET_MATCH_UPDATED = 'SOCKET_MATCH_UPDATED'
export const SOCKET_ASSIGNEDADMINS_UPDATED = 'SOCKET_ASSIGNEDADMINS_UPDATED'
export const PLAY_TRIAL_VIDEO = 'PLAY_TRIAL_VIDEO'
export const SET_VIDEO_TIMER = 'SET_VIDEO_TIMER'
export const PROCESS_STREAM_LINK = 'PROCESS_STREAM_LINK'
export const SET_INNINGS_TAB = 'SET_INNINGS_TAB'
export const FETCH_UNIQUE_USAGE_DASHBOARD = 'FETCH_UNIQUE_USAGE_DASHBOARD'
export const FETCH_CHANNEL_USAGE_DASHBOARD = 'FETCH_CHANNEL_USAGE_DASHBOARD'
export const FETCH_MATCH_USAGE_DASHBOARD = 'FETCH_MATCH_USAGE_DASHBOARD'
export const FETCH_OPEN_USAGE_DASHBOARD = 'FETCH_OPEN_USAGE_DASHBOARD'
export const FETCH_FINAL_USAGE_DASHBOARD = 'FETCH_FINAL_USAGE_DASHBOARD'
export const FETCH_ENGAGEMENT = 'FETCH_ENGAGEMENT'
export const RESET_DASHBOARD_DATA = 'RESET_DASHBOARD_DATA'
export const LOAD_MATCHES_WITH_ADDITIONAL_DATA = 'LOAD_MATCHES_WITH_ADDITIONAL_DATA'
export const LOAD_ADDITIONAL_TABLE_DATA = 'LOAD_ADDITIONAL_TABLE_DATA'
export const LOAD_ADDITIONAL_STATS_DATA = 'LOAD_ADDITIONAL_STATS_DATA'
export const LOAD_PREDICTION_QUESTIONS = 'LOAD_PREDICTION_QUESTIONS'
export const CREATE_PREDICTION_QUESTION = 'CREATE_PREDICTION_QUESTION'
export const UPDATE_PREDICTION_QUESTION = 'UPDATE_PREDICTION_QUESTION'
export const DELETE_PREDICTION_QUESTION = 'DELETE_PREDICTION_QUESTION'

export const LOAD_PREDICTION_COMPETITIONS = 'LOAD_PREDICTION_COMPETITIONS'
export const CREATE_PREDICTION_COMPETITION = 'CREATE_PREDICTION_COMPETITION'
export const UPDATE_PREDICTION_COMPETITION = 'UPDATE_PREDICTION_COMPETITION'
export const DELETE_PREDICTION_COMPETITION = 'DELETE_PREDICTION_COMPETITION'

export const LOAD_EXTERNAL_IDS = 'LOAD_EXTERNAL_IDS'
export const LOAD_PARSER_MATCHES = 'LOAD_PARSER_MATCHES'

export const LOAD_TASKS = 'LOAD_TASKS'
