export default [
  // {
  //     title: 'Start New Football Match (Barcelona vs Real Madrid)',
  //     url: 'https://s3-ap-southeast-2.amazonaws.com/com.tribelabs.static/videos/video.mp4',
  //     sport: 'soccer',
  //     id: 1,
  //     type: 'default',
  //     match: {
  //         awayCId: 'bbbbbbbbb2',
  //         homeCId: 'bbbbbbbbb1',
  //         tournamentGroupCId: 'bbbbbbbbb',
  //         sport: 'soccer',
  //         seasonCId: 'e04d7551f8252b4210aeea6fc1c3ee029705ba43',
  //         homeFormation: '4-3-3',
  //         awayFormation: '4-5-1',
  //         homeScore: 0,
  //         awayScore: 0,
  //         status: 'scheduled'
  //     }
  // },
  {
    // title: 'START NEW: Football (ManCity vs Arsenal)',
    url: 'https://s3-ap-southeast-2.amazonaws.com/com.tribelabs.static/videos/arsenal-mancity.mp4',
    sport: 'soccer',
    id: 2,
    type: 'default',
    match: {
      awayCId: '438f41623a82bc154dff9969beb9361a284c34c0',
      homeCId: '33217775f6c2e0f892c927f59ea3907572347ea8',
      tournamentGroupCId: 'bbbbbbbbb',
      sport: 'soccer',
      seasonCId: '8397767b6d464acf02cdd2d2567aca3d3c643c64',
      homeFormation: '4-3-3',
      awayFormation: '4-5-1',
      homeScore: 0,
      awayScore: 0,
      status: 'scheduled',
    },
    homeTeamName: 'ManCity',
    awayTeamName: 'Arsenal',
  },
  {
    // title: 'START NEW: Rugby Union (Leinster vs Saracens)',
    url: 'https://s3-ap-southeast-2.amazonaws.com/com.tribelabs.static/videos/leinster-scarlets.mp4',
    sport: 'rugbyUnion',
    id: 7,
    type: 'default',
    match: {
      homeCId: '0041ae96ea59a92b4248323377f17a7c69f4829d',
      awayCId: 'cecd2f3e798b0b30b787391bea72f3632b2986c0',
      tournamentGroupCId: 'aaaaaaaaa',
      sport: 'rugbyUnion',
      seasonCId: 'fcc70da8780e68e45c8b836432650168f77d1d87',
      homeScore: 0,
      awayScore: 0,
      status: 'scheduled',
    },
    homeTeamName: 'Leinster',
    awayTeamName: 'Saracens',
  },
  {
    // title: 'START NEW: Rugby Union (NZ Maori vs British & Irish Lions)',
    url: 'https://s3-ap-southeast-2.amazonaws.com/com.tribelabs.static/videos/maori_.mp4',
    sport: 'rugbyUnion',
    id: 3,
    type: 'default',
    match: {
      homeCId: 'ad426d6fc46e5d59b7dbbe30b30f3c0d019b6ce5',
      awayCId: '18c10362d78f31e5bb5732394649b16433a4c301',
      tournamentGroupCId: 'aaaaaaaaa',
      sport: 'rugbyUnion',
      seasonCId: 'fcc70da8780e68e45c8b836432650168f77d1d87',
      homeScore: 0,
      awayScore: 0,
      status: 'scheduled',
    },
    homeTeamName: 'NZ Maori',
    awayTeamName: 'British & Irish Lions',
  },
  {
    // title: 'START NEW: Rugby League (Storm vs Rhinos)',
    url: 'https://s3-ap-southeast-2.amazonaws.com/com.tribelabs.static/videos/rugby_league_1.webm',
    sport: 'rugbyLeague',
    id: 8,
    type: 'default',
    match: {
      homeCId: '1de6cb46cf1e9cc7bb6eb0d806ff80d9ea758d7a',
      awayCId: 'b36f10a8f669f0e76cc3afd9db3f72264daacea8',
      tournamentGroupCId: 'dddddddddd',
      sport: 'rugbyLeague',
      seasonCId: '016a2cfd66428cf6f0d38b226f31234c93d8d27b',
      homeScore: 0,
      awayScore: 0,
      status: 'scheduled',
    },
    homeTeamName: 'Storm',
    awayTeamName: 'Rhinos',
  },
  {
    // title: 'START NEW: Rugby League (Wigan vs Warrington)',
    url: 'https://s3-ap-southeast-2.amazonaws.com/com.tribelabs.static/videos/rugby_league_2.mp4',
    sport: 'rugbyLeague',
    id: 9,
    type: 'default',
    match: {
      homeCId: 'b7b3e7bf29278be0631424ab7ac2be86abb09c7e',
      awayCId: '6b68592761cd1886d9207271261ae3a166beae07',
      tournamentGroupCId: 'dddddddddd',
      sport: 'rugbyLeague',
      seasonCId: '016a2cfd66428cf6f0d38b226f31234c93d8d27b',
      homeScore: 0,
      awayScore: 0,
      status: 'scheduled',
    },
    homeTeamName: 'Wigan',
    awayTeamName: 'Warrington',
  },
  {
    // title: 'START NEW: Aussie Rules (West Coast vs Collingwood)',
    url: 'https://s3-ap-southeast-2.amazonaws.com/com.tribelabs.static/videos/afl_1.mp4',
    sport: 'aussieRules',
    id: 10,
    type: 'default',
    match: {
      homeCId: '4392c51a9429a84bd1f49dccec2b10ab2eab2718',
      awayCId: 'c3bc337db85859a99e553cbce57031c2fc16abb6',
      tournamentGroupCId: 'hhhhhhhhh',
      sport: 'aussieRules',
      seasonCId: '16a65bfd0e115746dbd3ce22bcc9e120841f1ff4',
      homeScore: 0,
      awayScore: 0,
      awayBehinds: 0,
      awayGoals: 0,
      homeBehinds: 0,
      homeGoals: 0,
      status: 'scheduled',
    },
    homeTeamName: 'West Coast',
    awayTeamName: 'Collingwood',
  },

  // {
  //     title: 'Start New American Football Match',
  //     sport: 'americanFootball',
  //     id: 4,
  //     type: 'default',
  //     match: {
  //         awayCId: '4a34b1ec33c8c8300c55c416a1d332d8ffc65d38',
  //         homeCId: '974aeb610ba68074706f5afec9ae74aaaf98273d',
  //         tournamentGroupCId: 'jjjjjjjjj',
  //         sport: 'americanFootball',
  //         seasonCId: '28012844ece577151d11b881bc5a3afb16101aad',
  //         homeFormation: '4-3-3',
  //         awayFormation: '4-5-1',
  //         homeScore: 0,
  //         awayScore: 0,
  //         status: 'scheduled'
  //     }
  // },
  {
    // title: 'Arsenal vs Manchester United Advanced',
    url: 'https://s3-ap-southeast-2.amazonaws.com/com.tribelabs.static/videos/video.mp4',
    sport: 'soccer',
    id: 5,
    type: 'advanced',
    match: {
      awayCId: 'bbbbbbbbb2',
      homeCId: 'bbbbbbbbb1',
      tournamentGroupCId: 'bbbbbbbbb',
      sport: 'soccer',
      seasonCId: '8397767b6d464acf02cdd2d2567aca3d3c643c64',
      homeFormation: '4-3-3',
      awayFormation: '4-5-1',
      homeScore: 0,
      awayScore: 0,
      status: 'scheduled',
    },
    homeTeamName: 'Arsenal',
    awayTeamName: 'Manchester United',
  },
  {
    // title: 'Start New Football Match (Barcelona vs Real Madrid) Advanced',
    url: 'https://s3-ap-southeast-2.amazonaws.com/com.tribelabs.static/videos/video.mp4',
    sport: 'soccer',
    id: 6,
    type: 'advanced',
    match: {
      awayCId: 'bbbbbbbbb2',
      homeCId: 'bbbbbbbbb1',
      tournamentGroupCId: 'bbbbbbbbb',
      sport: 'soccer',
      seasonCId: '8397767b6d464acf02cdd2d2567aca3d3c643c64',
      homeFormation: '4-3-3',
      awayFormation: '4-5-1',
      homeScore: 0,
      awayScore: 0,
      status: 'scheduled',
    },
    homeTeamName: 'Barcelona',
    awayTeamName: 'Real Madrid',
  },
  // {
  //   // title: 'START NEW: Cricket (West Indies vs England)',
  //   url: 'https://s3-ap-southeast-2.amazonaws.com/com.tribelabs.static/videos/cricket_full_test_day.mp4',
  //   sport: 'cricket',
  //   id: 11,
  //   type: 'default',
  //   match: {
  //     homeCId: 'f6046ccbebb789a11aaedbe9aa53209b12c1d6e9',
  //     awayCId: '679ea588c94fab8bdd9750485b54e78da9cd24f4',
  //     tournamentGroupCId: 'ggggggggg',
  //     sport: 'cricket',
  //     seasonCId: 'acfbf194b2a9e376e2bbef0d08d12aee6f1b60bf',
  //     status: 'inprogress',
  //     inningScore: [
  //       {
  //         number: 1,
  //         allottedOvers: 50,
  //         wickets: 0,
  //         displayScore: '0/0',
  //         score: 0,
  //         displayOvers: '0.0',
  //         teamCId: 'f6046ccbebb789a11aaedbe9aa53209b12c1d6e9'
  //       },
  //       { number: 2, allottedOvers: 50, displayScore: '-', teamCId: '679ea588c94fab8bdd9750485b54e78da9cd24f4', score: 0 }
  //     ],
  //     cricketType: 'odi'
  //   },
  //   homeTeamName: 'West Indies',
  //   awayTeamName: 'England'
  // },
  // {
  //   // title: 'START NEW: Cricket (Sydney Sixers v Melbourne Stars)',
  //   url: 'https://s3-ap-southeast-2.amazonaws.com/com.tribelabs.static/videos/FULL MATCH_ Sydney Sixers v Melbourne Stars (Jan 23, 2018) - BBL.mp4',
  //   sport: 'cricket',
  //   id: 12,
  //   type: 'default',
  //   match: {
  //     homeCId: 'b2e1251a91a5e159327e6677588aca5cd074feb7',
  //     awayCId: 'f2a548ace6a0b5f06dc9c1bd5ad1166796a41761',
  //     tournamentGroupCId: 'ggggggggg',
  //     sport: 'cricket',
  //     seasonCId: 'acfbf194b2a9e376e2bbef0d08d12aee6f1b60bf',
  //     status: 'inprogress',
  //     inningScore: [
  //       {
  //         number: 1,
  //         allottedOvers: 20,
  //         wickets: 0,
  //         displayScore: '0/0',
  //         score: 0,
  //         displayOvers: '0.0',
  //         teamCId: 'b2e1251a91a5e159327e6677588aca5cd074feb7'
  //       },
  //       { number: 2, allottedOvers: 20, displayScore: '-', teamCId: 'f2a548ace6a0b5f06dc9c1bd5ad1166796a41761', score: 0 }
  //     ],
  //     cricketType: 't20d'
  //   },
  //   homeTeamName: 'Sydney Sixers',
  //   awayTeamName: 'Melbourne Stars'
  // }
]
