import addBreakTypes from './../../../values/cricket/addBreakTypes'
import { findIndex, find } from 'lodash'
import isTestCricket from '../../../helpers/commentary/cricket/isTestCricket'

export const prepareValuesForMatchAndFactOnAddBreak = (matchBreak, match) => {
  let period = null
  let matchStatus = null
  let status = match.status
  let type = 'period_start'
  let matchStatusMytribe = ''
  let matchStatusChannel = ''
  let matchStatusShort = ''
  let matchStatusLong = ''

  const breakIndex = findIndex(addBreakTypes, { type: matchBreak })
  if (breakIndex >= 0) {
    const currentMatchBreak = addBreakTypes[breakIndex]
    matchStatus = currentMatchBreak.matchStatus
    if (currentMatchBreak.period) period = currentMatchBreak.period
    if (currentMatchBreak.status) status = currentMatchBreak.status
    // if (currentMatchBreak.type) type = currentMatchBreak.type
    if (match.status === 'scheduled' && !['abandoned'].includes(matchStatus)) {
      matchStatusMytribe = currentMatchBreak.title
      matchStatusChannel = currentMatchBreak.title
      matchStatusShort = currentMatchBreak.title
    }
    if (match.status === 'toss' && !['abandoned'].includes(matchStatus)) {
      matchStatusMytribe = currentMatchBreak.title
      matchStatusShort = currentMatchBreak.title
      matchStatusLong = currentMatchBreak.title
    }

    if (matchBreak === 'match_ended') {
      type = 'match_ended'
    }
    if (matchStatus === 'innings_break') {
      const nextInning = parseInt(match.currentInning, 10) + 1
      if (nextInning === 2) {
        match.inningScore[1] = {
          ...match.inningScore[1],
          allottedOvers: match.inningScore[1].allottedOvers || match.inningScore[0].allottedOvers || null,
          target: match.inningScore[1].target || null,
        }
      }
    }
  } else {
    period = 'period_started'
  }

  return { matchStatus, status, period, type, matchStatusMytribe, matchStatusChannel, matchStatusShort, matchStatusLong }
}

export const getBreakHeader = (ballList, match) => {
  const deleted = ballList[ballList.length - 1].status === 'deleted'
  const type = ballList[ballList.length - 1].type
  const period = ballList[ballList.length - 1].period
  const condition = type === 'period_start' ? { period } : { factType: type }
  const breakData = find(addBreakTypes, condition)
  let header = breakData ? breakData.heading || breakData.title : ''
  if (isTestCricket(match.cricketType)) {
    header = 'Day ' + ballList[ballList.length - 1].day + ' - ' + header
  }
  if (deleted) header += ' (Deleted)'
  return header
}

export const getFactTypeForBreak = (matchStatus) => {
  // waas changed from closed to match ended
  if (['match_ended'].includes(matchStatus)) return 'close_of_play'
  const matchBreak = find(addBreakTypes, { matchStatus })
  return matchBreak ? matchBreak.factType : ''
}
