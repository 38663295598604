import normalizeData from '../../helpers/normalizeData'
import updateNormalizedData from '../../helpers/updateNormalizedData'
import createNormalizedData from '../../helpers/createNormalizedData'
import removeNormalizedData from '../../helpers/removeNormalizedData'

export default (mergeWith) => {
  return {
    loadPredictionCompetitions(competitions) {
      mergeWith({ predictionCompetitions: normalizeData(competitions, null, '_id') })
      return this
    },
    createPredictionCompetition(competition) {
      mergeWith(({ predictionCompetitions }) => {
        return { predictionCompetitions: createNormalizedData(competition, predictionCompetitions, '_id') }
      })
      return this
    },
    updatePredictionCompetition(competition) {
      mergeWith(({ predictionCompetitions }) => {
        return { predictionCompetitions: updateNormalizedData(competition, predictionCompetitions, '_id') }
      })
      return this
    },
    deletePredictionCompetition(competition) {
      mergeWith(({ predictionCompetitions }) => {
        return { predictionCompetitions: removeNormalizedData(competition, predictionCompetitions, '_id') }
      })
      return this
    }
  }
}
