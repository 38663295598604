import typeToReducer from 'type-to-reducer'
import { PENDING, REJECTED, FULFILLED } from '../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../constants/ActionType'
import MatchFacade from '../facades/match'

export default typeToReducer(
  {
    [ActionTypes.LOAD_MATCH]: {
      [PENDING](state, { payload }) {
        return MatchFacade(state).startLoading().get()
      },
      [FULFILLED](state, { meta, payload }) {
        const { matchCId } = meta
        return MatchFacade(state).addMatchesToFullyLoaded(matchCId).finishLoading().get()
      },
      [REJECTED](state, { payload }) {
        return MatchFacade(state).load(null).finishLoading().get()
      }
    },
    [ActionTypes.OPEN_MATCH_EDIT_POPUP]: (state, { payload }) => {
      const { matchCId } = payload
      return MatchFacade(state).openMatchEditPopup(matchCId).get()
    },
    [ActionTypes.OPEN_MATCH_ADD_POPUP]: (state, { payload }) => {
      const { match } = payload
      return MatchFacade(state).openMatchAddPopup(match).get()
    },
    [ActionTypes.CLOSE_MATCH_EDIT_POPUP]: (state) => {
      return MatchFacade(state).closeMatchEditPopup().get()
    },
    [ActionTypes.OPEN_SCORE_EDIT_POPUP]: (state, { payload }) => {
      const { matchCId } = payload
      return MatchFacade(state).openEditScorePopup(matchCId).get()
    },
    [ActionTypes.CLOSE_SCORE_EDIT_POPUP]: (state) => {
      return MatchFacade(state).closeScoreEditPopup().get()
    },
    [ActionTypes.OPEN_MATCH_LIFE_CYCLE_POPUP]: (state, { payload }) => {
      const { matchCId } = payload
      return MatchFacade(state).openMatchLifeCyclePopup(matchCId).get()
    },
    [ActionTypes.CLOSE_MATCH_LIFE_CYCLE_POPUP]: (state) => {
      return MatchFacade(state).closeMatchLifeCyclePopup().get()
    },
    [ActionTypes.OPEN_MATCH_STATUS_EDIT_POPUP]: (state, { payload }) => {
      const { matchCId } = payload
      return MatchFacade(state).openMatchStatusEditPopup(matchCId).get()
    },
    [ActionTypes.CLOSE_MATCH_STATUS_EDIT_POPUP]: (state) => {
      return MatchFacade(state).closeMatchStatusEditPopup().get()
    },
    [ActionTypes.OPEN_LIVE_SCORE_POPUP]: (state, { payload }) => {
      const { matchCId } = payload
      return MatchFacade(state).openLiveScorePopup(matchCId).get()
    },
    [ActionTypes.CLOSE_LIVE_SCORE_POPUP]: (state) => {
      return MatchFacade(state).closeLiveScorePopup().get()
    },
    [ActionTypes.OPEN_FINAL_SCORE_EDIT_POPUP]: (state, { payload }) => {
      const { matchCId } = payload
      return MatchFacade(state).openFinalScoreEditPopup(matchCId).get()
    },
    [ActionTypes.CLOSE_FINAL_SCORE_EDIT_POPUP]: (state) => {
      return MatchFacade(state).closeFinalScoreEditPopup().get()
    },
    [ActionTypes.CLOSE_ADD_MATCH_POPUP]: (state) => {
      return MatchFacade(state).closeAddMatchPopup().get()
    },
    [ActionTypes.RESET_ALL_MATCHES]: (state) => {
      return MatchFacade(state).resetAllMatches().get()
    },
    [ActionTypes.LOAD_MATCHES_BY_SEASON]: {
      [PENDING](state) {
        return MatchFacade(state).startLoading().get()
      },
      [FULFILLED](state) {
        return MatchFacade(state).finishLoading().get()
      },
      [REJECTED](state) {
        return MatchFacade(state).finishLoading().get()
      }
    },
    [ActionTypes.LOAD_MATCHES_BY_DATE]: {
      [PENDING](state, { payload }) {
        return MatchFacade(state).startLoading().get()
      },
      [FULFILLED](state, { payload, meta }) {
        const { loadMatchesByDate, loadMatchesByAdminUser } = payload
        const { endDate } = meta
        return MatchFacade(state)
          .loadAllMatch(loadMatchesByDate || loadMatchesByAdminUser, endDate)
          .finishLoading()
          .get()
      },
      [REJECTED](state, { payload }) {
        const { loadMatchesByDate } = payload
        return MatchFacade(state).loadAllMatch(loadMatchesByDate).finishLoading().get()
      }
    },
    [ActionTypes.LOAD_MATCHES_BY_ADMIN_USER]: {
      [PENDING](state, { payload }) {
        return MatchFacade(state).startLoading().get()
      },
      [FULFILLED](state, { meta, payload }) {
        const { loadMatchesByAdminUser } = payload
        return MatchFacade(state).loadAllMatch(loadMatchesByAdminUser).finishLoading().get()
      },
      [REJECTED](state, { payload }) {
        const { loadMatchesByAdminUser } = payload
        return MatchFacade(state).loadAllMatch(loadMatchesByAdminUser).finishLoading().get()
      }
    },
    [ActionTypes.ENABLE_BULK_EDIT_MODE]: (state, { payload }) => {
      const { status } = payload
      return MatchFacade(state).enableBulkEditMode(status).get()
    },
    [ActionTypes.ENABLE_CRICKET_DATA_EDIT_MODE]: (state, { payload }) => {
      const { matchCId } = payload
      return MatchFacade(state).enableCricketDataEditMode(matchCId).get()
    },
    [ActionTypes.CLOSE_CRICKET_CONTROL_FORM]: (state) => {
      return MatchFacade(state).closeCricketControlPanelForm().get()
    }
  },
  MatchFacade().get()
)
