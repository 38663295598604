import createPromisifiedFacade from 'facade-tools/lib/createPromisifiedFacade'

const initialState = {
  lineups: null
}

const LineupFacade = createPromisifiedFacade(mergeWith => {
  return {
    load(matchCId, lineup) {
      mergeWith(({ lineups }) => {
        lineups = {
          [matchCId]: { ...lineup }
        }
        return { lineups }
      })
      return this
    },
    openLineupEditPopup(matchCId) {
      mergeWith(({ lineups }) => {
        lineups = {
          [matchCId]: { ...lineups[matchCId], isEdit: true }
        }
        return { lineups }
      })
      return this
    },
    closeLineupEditPopup(matchCId) {
      mergeWith(({ lineups }) => {
        lineups = {
          [matchCId]: { ...lineups[matchCId], isEdit: false }
        }
        return { lineups }
      })
      return this
    }
  }
}, initialState)

export default LineupFacade