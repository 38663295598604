const getPlayersFromTeams = teams => {
  let players = []
  teams.map(team => {
    if(team.players) {
      team.players.map(player => {
        players.push(player)
        return player
      })
    }
    return team
  })
  return players
}

export default getPlayersFromTeams
