import * as ActionTypes from '../constants/ActionType'
import adminUser from '../graphql/queries/adminUser'
import updateVideoTimer from '../graphql/mutations/updateVideoTimer'
import setMatchAvailabilityMutation from '../graphql/mutations/setMatchAvailability'
import query from '../apollo/query'
import mutate from '../apollo/mutate'

export const loadAdminUser = () => {
  return {
    type: ActionTypes.LOAD_ADMIN_USER,
    payload: query(adminUser),
  }
}

export const saveVideoTimer = (videoTimer) => {
  return {
    type: ActionTypes.UPDATE_VIDEO_TIMER,
    meta: {
      videoTimer,
    },
    payload: mutate(updateVideoTimer, { videoTimer }),
  }
}

export const setMatchAvailability = (matchCId, availability) => {
  return {
    type: ActionTypes.SET_MATCH_AVAILABILITY,
    meta: {
      availability,
      matchCId,
    },
    payload: mutate(setMatchAvailabilityMutation, { matchCId, availability }),
  }
}

export const setCricketAvailability = (matchCId, availabilityFrom, availabilityTo, slot, status, day) => {
  let data = {
    matchCId,
    availabilityFrom,
    availabilityTo,
    slot,
    availability: status,
  }
  if (day) data.day = day
  return {
    type: ActionTypes.SET_MATCH_AVAILABILITY,
    meta: data,
    payload: mutate(setMatchAvailabilityMutation, data),
  }
}

export const setVideoTimer = (videoTimer) => {
  return {
    type: ActionTypes.SET_VIDEO_TIMER,
    payload: videoTimer,
  }
}
