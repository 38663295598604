import typeToReducer from 'type-to-reducer'
import { PENDING, REJECTED, FULFILLED } from '../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../constants/ActionType'
import BroadcastProgramFacade from '../facades/broadcastProgramme'

export default typeToReducer(
  {
    [ActionTypes.LOAD_BROADCAST_PROGRAMMES]: {
      [FULFILLED](state) {
        return BroadcastProgramFacade(state)
          .allProgrammesLoaded()
          .get()
      }
    },

    [ActionTypes.LOAD_BROADCAST_PROGRAM_MATCHES]: {
      [PENDING](state) {
        return BroadcastProgramFacade(state)
          .startLoadingMatches(true)
          .get()
      },
      [FULFILLED](state) {
        return BroadcastProgramFacade(state)
          .finishLoadingMatches(false)
          .get()
      },
      [REJECTED](state) {
        return BroadcastProgramFacade(state)
          .finishLoadingMatches(false)
          .get()
      }
    }
  },
  BroadcastProgramFacade().get()
)
