import cookie from 'js-cookie'

import { ACCESS_TOKEN_NAME } from '../constants/Token'

export default {
  get() {
    return cookie.get(ACCESS_TOKEN_NAME)
  },

  set(token) {
    cookie.set(ACCESS_TOKEN_NAME, token)
    return this
  },

  clear() {
    cookie.remove(ACCESS_TOKEN_NAME)
    return this
  }
}
