import normalizeData from '../../helpers/normalizeData'

export default (mergeWith) => {
  return {
    loadTeams(teamsList) {
      mergeWith(({ teams }) => {
        teams = normalizeData(teamsList, teams)
        return { teams }
      })
      return this
    }
  }
}
