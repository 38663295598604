const createNormalizedData = (object, intialData, key = 'customId') => {
  if (!intialData) {
    intialData = { allIds: [], byId: {} }
  }
  let byId = intialData && intialData.byId ? intialData.byId : {}
  byId[object[key]] = { ...object }
  intialData = { ...intialData, byId }
  intialData.allIds.push(object[key])

  return intialData
}

export default createNormalizedData
