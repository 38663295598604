import { createPromisifiedFacade } from 'facade-tools'

const initialState = {
  matchesLoading: false,
  matchesLoaded: false,
  allProgrammesLoaded: false
}

const BroadcastProgramFacade = createPromisifiedFacade((mergeWith) => {
  return {
    startLoadingMatches() {
      mergeWith({
        matchesLoading: true
      })
      return this
    },
    finishLoadingMatches() {
      mergeWith({
        matchesLoading: false,
        matchesLoaded: true
      })
      return this
    },

    allProgrammesLoaded() {
      mergeWith({
        allProgrammesLoaded: true
      })
      return this
    }
  }
}, initialState)

export default BroadcastProgramFacade
