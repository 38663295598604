import typeToReducer from 'type-to-reducer'
import { PENDING, REJECTED, FULFILLED } from '../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../constants/ActionType'
import MatchFactFacade from '../facades/matchFact'

// import { getFactTypeForBreak } from '../helpers/commentary/cricket/prepareBreakData'

export default typeToReducer(
  {
    [ActionTypes.CREATE_FACTS]: {
      [PENDING](state, { payload }) {
        return MatchFactFacade(state).setFactUpdateStatus(true).get()
      },
      [FULFILLED](state, { payload, meta }) {
        const { createOrUpdateFacts: facts } = payload
        const { match: matchByNewFact } = meta
        return MatchFactFacade(state).addOrUpdateFacts(facts, matchByNewFact).setFactUpdateStatus(false).get()
      }
    },
    [ActionTypes.LOAD_MATCH_FACTS]: {
      [PENDING](state, { payload }) {
        return MatchFactFacade(state).startLoading().get()
      },
      [FULFILLED](state, { payload, meta }) {
        const { matchFacts } = payload
        const { matchCId } = meta
        return MatchFactFacade(state).loadFacts(matchCId, matchFacts).finishLoading().get()
      },
      [REJECTED](state, { payload, meta }) {
        const { matchCId } = meta
        return MatchFactFacade(state).loadFacts(matchCId, []).finishLoading().get()
      }
    },
    [ActionTypes.CREATE_UPDATE_FACTS]: {
      [FULFILLED](state, { payload, meta }) {
        const { createOrUpdateFacts: facts } = payload
        const { matchCId } = meta
        return MatchFactFacade(state).loadFacts(matchCId, facts).get()
      }
    },
    [ActionTypes.UPDATE_FACTS]: {
      [PENDING](state, { payload }) {
        return MatchFactFacade(state).setFactUpdateStatus(true).get()
      },
      [FULFILLED](state, { payload }) {
        const { createOrUpdateFacts: facts } = payload
        return MatchFactFacade(state).addOrUpdateFacts(facts).setFactUpdateStatus(false).get()
      }
    },
    [ActionTypes.SET_IS_FACT_REARRAGING]: (state, { payload }) => {
      return MatchFactFacade(state).setIsFactRearraging(payload).get()
    },
    [ActionTypes.SOCKET_FACTS_CREATED]: (state, { payload }) => {
      const { facts } = payload
      // const matchCId = facts[0].matchCId
      return (
        MatchFactFacade(state)
          .addOrUpdateFacts(facts)
          // .removeTempFactOnSocketUpdation(matchCId, 'general')
          .get()
      )
    },
    [ActionTypes.ADD_FACTS_TO_STORE]: (state, { payload }) => {
      const { facts } = payload
      return MatchFactFacade(state).addOrUpdateFacts(facts).get()
    },
    [ActionTypes.REMOVE_FACT_FROM_STORE]: (state, { payload }) => {
      const { matchCId, condition } = payload
      return MatchFactFacade(state).removeFactsFromStore(matchCId, condition).get()
    },
    [ActionTypes.UPDATE_FACT_IN_STORE]: (state, { payload }) => {
      const { fact, condition } = payload
      return MatchFactFacade(state).updateFactInStore(fact, condition).get()
    },
    [ActionTypes.SOCKET_CRICKET_STATUSES_UPDATED]: (state, { payload }) => {
      // const { matchCId } = payload
      return MatchFactFacade(state) //.removeTempFactOnSocketUpdation(matchCId, 'ball')
        .get()
    },
    [ActionTypes.SOCKET_MATCH_UPDATED]: (state, { payload }) => {
      // const { customId, matchStatus } = payload
      // const type = getFactTypeForBreak(matchStatus)
      return MatchFactFacade(state) //.removeTempFactOnSocketUpdation(customId, type)
        .get()
    }
  },
  MatchFactFacade().get()
)
