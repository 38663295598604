import typeToReducer from 'type-to-reducer'

import * as ActionTypes from '../constants/ActionType'
import EntityFacade from '../facades/entity'
import adminUsers from './entity/adminUsers'
import league from './entity/league'
import season from './entity/season'
import venue from './entity/venue'
import team from './entity/team'
import match from './entity/match'
import socket from './entity/socket'
import partner from './entity/partner'
import broadcastProvider from './entity/broadcastProvider'
import broadcastChannel from './entity/broadcastChannel'
import broadcastProgramme from './entity/broadcastProgramme'
import setting from './entity/setting'
import cricketStats from './entity/cricketStats'
import quiz from './entity/quiz'
import quizQuestion from './entity/quizQuestion'
import organization from './entity/organization'
import additionalTableData from './entity/additionalTableData'
import additionalStatsData from './entity/additionalStatsData'
import predictionQuestion from './entity/predictionQuestion'
import predictionCompetition from './entity/predictionCompetition'
import externalIds from './entity/externalIds'
import task from './entity/task'

export default typeToReducer(
  {
    ...league,
    ...adminUsers,
    ...season,
    ...venue,
    ...team,
    ...match,
    ...socket,
    ...partner,
    ...broadcastProvider,
    ...broadcastChannel,
    ...broadcastProgramme,
    ...setting,
    ...cricketStats,
    ...quiz,
    ...quizQuestion,
    ...organization,
    ...additionalTableData,
    ...additionalStatsData,
    ...predictionQuestion,
    ...predictionCompetition,
    ...externalIds,
    ...task,
    [ActionTypes.UPDATE_ENTITY_STORE_BY_ID]: (state, { payload }) => {
      return EntityFacade(state).updateEntityStoreById(payload).get()
    },
    [ActionTypes.ADD_ENTITY_TO_STORE]: (state, { payload }) => {
      return EntityFacade(state).addEntityToStore(payload).get()
    },
  },
  EntityFacade().get()
)
