import createPromisifiedFacade from 'facade-tools/lib/createPromisifiedFacade'

const initialState = {
  allVenuesLoaded: false
}

const VenueFacade = createPromisifiedFacade(mergeWith => {
  return {
    allVenuesLoaded(isAllVenueLoaded) {
      mergeWith(({ allVenuesLoaded }) => {
        allVenuesLoaded = true
        return { allVenuesLoaded }
      })
      return this
    }
  }
}, initialState)

export default VenueFacade