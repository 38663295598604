import { createPromisifiedFacade } from 'facade-tools'

const initialState = {
  allProvidersLoaded: false,
  editProviderCId: null,
  addPopupIsOpen: null
}

const BroadcastProviderFacade = createPromisifiedFacade((mergeWith) => {
  return {
    openEditProviderPopup(providerCId) {
      mergeWith(({ editProviderCId }) => {
        editProviderCId = providerCId
        return { editProviderCId }
      })
      return this
    },
    closeEditProviderPopup() {
      mergeWith(({ editProviderCId }) => {
        editProviderCId = null
        return { editProviderCId }
      })
      return this
    },
    openAddProviderPopup() {
      mergeWith({
        addPopupIsOpen: true
      })
      return this
    },
    closeAddProviderPopup() {
      mergeWith({
        addPopupIsOpen: false
      })
      return this
    },
    allProvidersLoaded() {
      mergeWith(({ allProvidersLoaded }) => {
        allProvidersLoaded = true
        return { allProvidersLoaded }
      })
      return this
    }
  }
}, initialState)

export default BroadcastProviderFacade
