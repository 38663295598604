import { createPromisifiedFacade } from 'facade-tools'

import league from './entity/league'
import season from './entity/season'
import venue from './entity/venue'
import team from './entity/team'
import adminUsers from './entity/adminUsers'
import match from './entity/match'
import partner from './entity/partner'
import broadcastProviders from './entity/broadcastProviders'
import broadcastChannels from './entity/broadcastChannels'
import broadcastProgrammes from './entity/broadcastProgrammes'
import settings from './entity/settings'
import cricketStats from './entity/cricketStats'
import quiz from './entity/quiz'
import quizQuestion from './entity/quizQuestion'
import organization from './entity/organization'
import additionalTableData from './entity/additionalTableData'
import additionalStatsData from './entity/additionalStatsData'
import predictionQuestion from './entity/predictionQuestion'
import predictionCompetition from './entity/predictionCompetition'
import externalIds from './entity/externalIds'
import task from './entity/task'

const initialState = {
  leagues: null,
  seasons: null,
  matches: null,
  teams: null,
  venues: null,
  adminUsers: null,
  players: null,
  partners: null,
  broadcastProviders: null,
  broadcastChannels: null,
  broadcastProgrammes: null,
  settings: null,
  cricketStats: null,
  quizes: null,
  quizQuestion: null,
  organizations: null,
  additionalTableData: null,
  predictionQuestions: null,
  predictionCompetitions: null,
  externalIds: null,
  parserMatches: null,
  tasks: null,
  loader: {
    leagues: false,
    seasons: false,
    venues: false,
    adminUsers: false,
    teams: false,
    partners: false,
    broadcastProviders: false,
    broadcastChannels: false,
    broadcastProgrammes: false,
    settings: false,
    quizes: false,
    quizQuestion: false,
    organizations: false,
    additionalTableData: false,
    additionalStatsData: false,
    predictionQuestions: false,
    predictionCompetitions: false,
    externalIds: false,
    parserMatches: false,
    tasks: false,
  },
}

const EntityFacade = createPromisifiedFacade((mergeWith) => {
  return {
    ...league(mergeWith),
    ...season(mergeWith),
    ...team(mergeWith),
    ...adminUsers(mergeWith),
    ...match(mergeWith),
    ...venue(mergeWith),
    ...partner(mergeWith),
    ...broadcastProviders(mergeWith),
    ...broadcastChannels(mergeWith),
    ...broadcastProgrammes(mergeWith),
    ...settings(mergeWith),
    ...cricketStats(mergeWith),
    ...quiz(mergeWith),
    ...quizQuestion(mergeWith),
    ...organization(mergeWith),
    ...additionalTableData(mergeWith),
    ...additionalStatsData(mergeWith),
    ...predictionQuestion(mergeWith),
    ...predictionCompetition(mergeWith),
    ...externalIds(mergeWith),
    ...task(mergeWith),
    startLoadingEntity(entityName) {
      mergeWith(({ loader }) => {
        loader[entityName] = true
        return { loader }
      })
      return this
    },
    finishLoadingEntity(entityName) {
      mergeWith(({ loader }) => {
        loader[entityName] = false
        return { loader }
      })
      return this
    },
    updateEntityStoreById({ entityName, id, values }) {
      mergeWith((entityObject) => {
        let entity = entityObject[entityName]
        let byId = entity.byId
        byId[id] = { ...byId[id], ...values }
        entity = { ...entity, byId }
        entityObject[entityName] = entity
        return entityObject
      })
      return this
    },
    addEntityToStore({ entityName, values }) {
      mergeWith((entityArray) => {
        let entity = entityArray[entityName]
        entity.allIds.push(values.customId)
        let byId = entity.byId
        byId[values.customId] = values
        entity = { ...entity, byId }
        entityArray[entityName] = entity
        return entityArray
      })
      return this
    },
  }
}, initialState)

export default EntityFacade
