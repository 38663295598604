import normalizeData from '../../helpers/normalizeData'
import updateNormalizedData from '../../helpers/updateNormalizedData'
import createNormalizedData from '../../helpers/createNormalizedData'

export default (mergeWith) => {
  return {
    loadTasks(taskList) {
      mergeWith({ tasks: normalizeData(taskList, null, '_id') })
      return this
    },
    createTask(task) {
      mergeWith(({ tasks }) => {
        return { tasks: createNormalizedData(task, tasks, '_id') }
      })
      return this
    },
    updateTask(task) {
      mergeWith(({ tasks }) => {
        return { tasks: updateNormalizedData(task, tasks, '_id') }
      })
      return this
    },
  }
}
