import React, { useState } from 'react'
import { Route } from 'react-router-dom'
import Sidebar from './Sidebar'

const PrivateRoute = ({ sideBar, roles, isAuthenticated, loadAdminUserIfNeeded, adminUser, component: Component, redirectUserBasedOnRole, ...rest }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
  if (!isAuthenticated) {
    window.location.href = 'https://admin.tribesportsapp.com/logout'
    return null
    // return <Redirect {...{ to: '/not-authorized' }} />
  }
  loadAdminUserIfNeeded()
  if (adminUser && adminUser.role) {
    window.$crisp.push(['set', 'user:email', adminUser.email])
    window.$crisp.push(['set', 'user:nickname', adminUser.firstName + ' ' + adminUser.lastName])
    // console.log('adminUser', adminUser.firstName)

    if (!roles || roles.indexOf('all') !== -1 || roles.indexOf(adminUser.role) !== -1) {
      const render = (renderProps) => {
        return <Component {...renderProps} {...{ sidebarCollapsed }} />
      }
      if (sideBar) {
        return (
          <Sidebar {...rest} {...{ sidebarCollapsed, setSidebarCollapsed }}>
            <Route {...rest} {...{ render }} />{' '}
          </Sidebar>
        )
      } else {
        return <Route {...rest} {...{ render }} />
      }
    } else {
      redirectUserBasedOnRole()
      return null
    }
  } else {
    return null
  }
}

export default PrivateRoute
