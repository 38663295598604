import typeToReducer from 'type-to-reducer'
import { FULFILLED } from '../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../constants/ActionType'
import BroadcastProviderFacade from '../facades/broadcastProvider'

export default typeToReducer(
  {
    [ActionTypes.OPEN_EDIT_PROVIDER_POPUP]: (state, { payload }) => {
      return BroadcastProviderFacade(state)
        .openEditProviderPopup(payload)
        .get()
    },
    [ActionTypes.CLOSE_EDIT_PROVIDER_POPUP]: (state) => {
      return BroadcastProviderFacade(state)
        .closeEditProviderPopup()
        .get()
    },
    [ActionTypes.LOAD_BROADCAST_PROVIDERS]: {
      [FULFILLED](state) {
        return BroadcastProviderFacade(state)
          .allProvidersLoaded()
          .get()
      }
    },
    [ActionTypes.OPEN_ADD_PROVIDER_POPUP]: (state) => {
      return BroadcastProviderFacade(state)
        .openAddProviderPopup()
        .get()
    },
    [ActionTypes.CLOSE_ADD_PROVIDER_POPUP]: (state) => {
      return BroadcastProviderFacade(state)
        .closeAddProviderPopup()
        .get()
    }
  },
  BroadcastProviderFacade().get()
)
