// prettier-ignore
export default [
  { type: 'rain_stoppage', title: 'Rain Delay',     period: 'Interrupted',    factType: 'rain_stoppage',   matchStatus: 'interrupted' },
  { type: 'bad_light',     title: 'Bad Light',      period: 'Bad light',      factType: 'bad_light',       matchStatus: 'bad_light' },
  { type: 'play_halted',   title: 'Game Delay',     period: 'Game delay',     factType: 'play_halted',     matchStatus: 'play_halted', heading: 'Match Delay' },
  { type: 'lunch_break',   title: 'Lunch',          period: 'Lunch break',    factType: 'lunch',           matchStatus: 'lunch_break', sendPN: true },
  { type: 'tea_break',     title: 'Tea',            period: 'Tea break',      factType: 'tea',             matchStatus: 'tea_break', sendPN: true },
  { type: 'dinner_break',  title: 'Dinner',         period: 'Dinner break',   factType: 'dinner',          matchStatus: 'dinner_break', sendPN: true },
  { type: 'day_start',     title: 'Day Start',      period: 'Day start',      factType: 'day_start',       matchStatus: 'day_start' },
  { type: 'stumps',        title: 'Stumps',         period: 'Stumps',         factType: 'stumps',          matchStatus: 'stumps', sendPN: true },
  { type: 'drinks',        title: 'Drinks',         period: 'Drinks',         factType: 'drinks',          matchStatus: 'drinks' },
  { type: 'innings_break', title: 'Innings Break',  period: 'Innings break',  factType: 'innings_break',   matchStatus: 'innings_break', sendPN: true },
  { type: 'abandoned',     title: 'Abandoned',      period: 'Abandoned',      factType: 'match_abandoned', matchStatus: 'abandoned', status: 'closed' },
  { type: 'match_ended',   title: 'End Match',                                factType: 'match_ended',     matchStatus: 'match_ended', status: 'closed', heading: 'Match Ended', sendPN: true },
  { factType: 'general' },
  { factType: 'toss' },
  { factType: 'close_of_play', title: 'Close of Play'},
  { factType: 'period_start' },
  { factType: 'match_started' },
  { factType: 'half_century' },
  { factType: 'change_of_bowler' },
  { factType: 'next_batsman_in' },
  { factType: 'udrs' },
  { factType: 'milestone' }
]
