import normalizeData from '../../helpers/normalizeData'

export default (mergeWith) => {
  return {
    loadBroadcastProviders(broadcastProvidersList) {
      mergeWith(({ broadcastProviders }) => {
        broadcastProviders = normalizeData(broadcastProvidersList)
        return { broadcastProviders }
      })
      return this
    },
    createBroadcastProvider(createBroadcastProviders) {
      mergeWith(({ broadcastProviders }) => {
        if (!broadcastProviders) {
          broadcastProviders = { allIds: [], byId: {} }
        }
        let byId = broadcastProviders && broadcastProviders.byId ? broadcastProviders.byId : {}
        const { customId } = createBroadcastProviders
        broadcastProviders.allIds.push(customId)
        byId[customId] = { ...byId[customId], ...createBroadcastProviders }
        broadcastProviders = { ...broadcastProviders, byId }
        return { broadcastProviders }
      })
      return this
    },
    updateBroadcastProvider(updatedBroadcastProviders) {
      mergeWith(({ broadcastProviders }) => {
        let byId = broadcastProviders && broadcastProviders.byId ? broadcastProviders.byId : {}
        for (let broadcastProvider of updatedBroadcastProviders) {
          const { customId } = broadcastProvider
          byId[customId] = { ...byId[customId], ...broadcastProvider }
        }
        broadcastProviders = { ...broadcastProviders, byId }
        return { broadcastProviders }
      })
      return this
    }
  }
}
