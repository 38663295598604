import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_ADMIN_USERS]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state).startLoadingEntity('adminUsers').get()
    },
    [FULFILLED](state, { payload }) {
      const { adminUsers } = payload
      return EntityFacade(state).loadAdminUsers(adminUsers).finishLoadingEntity('adminUsers').get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state).loadAdminUsers([]).finishLoadingEntity('adminUsers').get()
    }
  }
}
