export default (mergeWith) => {
  return {
    loadSettings(settingsList) {
      mergeWith(({ settings }) => {
        return { settings: settingsList }
      })
      return this
    },
    updateSetting(setting, value) {
      mergeWith(({ settings }) => {
        settings = { ...settings, [setting]: value }
        return { settings }
      })
      return this
    }
  }
}
