import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_ADDITIONAL_STATS_DATA]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state).startLoadingEntity('additionalStatsData').get()
    },
    [FULFILLED](state, { payload }) {
      const { additionalStatsData } = payload
      return EntityFacade(state).loadAdditionalStatsData(additionalStatsData).finishLoadingEntity('additionalStatsData').get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state).loadAdditionalStatsData([]).finishLoadingEntity('additionalStatsData').get()
    },
  },
  [ActionTypes.RESET_ADDITIONAL_STATS]: (state) => {
    return EntityFacade(state).resetAdditionalStats().get()
  },
}
