import { createPromisifiedFacade } from 'facade-tools'

const initialState = {
  quizPopup: null,
  questionPopup: null,
  roundPopup: null,
  instancePopup: null,
  archivePopup: null
}

const QuizFacade = createPromisifiedFacade((mergeWith) => {
  return {
    openQuizPopup(payload) {
      mergeWith({
        quizPopup: payload
      })
      return this
    },
    closeQuizPopup() {
      mergeWith({
        quizPopup: null
      })
      return this
    },
    openQuestionPopup(payload) {
      mergeWith({
        questionPopup: payload
      })
      return this
    },
    closeQuestionPopup() {
      mergeWith({
        questionPopup: null
      })
      return this
    },
    openRoundPopup(payload) {
      mergeWith({
        roundPopup: payload
      })
      return this
    },
    closeRoundPopup() {
      mergeWith({
        roundPopup: null
      })
      return this
    },
    openInstancePopup(payload) {
      mergeWith({
        instancePopup: payload
      })
      return this
    },
    closeInstancePopup() {
      mergeWith({
        instancePopup: null
      })
      return this
    },
    openArchivePopup(payload) {
      mergeWith({
        archivePopup: payload
      })
      return this
    },
    closeArchivePopup() {
      mergeWith({
        archivePopup: null
      })
      return this
    }
  }
}, initialState)

export default QuizFacade
