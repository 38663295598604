import isObject from 'is-plain-object'
import unset from 'unset-value'

const omitDeep = (value, keys) => {
  if (typeof value === 'undefined') {
    return {}
  }

  if (Array.isArray(value)) {
    for (var i = 0; i < value.length; i++) {
      value[i] = omitDeep(value[i], keys)
    }
    return value
  }

  if (!isObject(value)) {
    return value
  }

  if (typeof keys === 'string') {
    keys = [keys]
  }

  if (!Array.isArray(keys)) {
    return value
  }

  for (var j = 0; j < keys.length; j++) {
    unset(value, keys[j])
  }

  for (var key in value) {
    if (value.hasOwnProperty(key)) {
      value[key] = omitDeep(value[key], keys)
    }
  }

  return value
}

export default omitDeep
