import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_QUIZ_QUESTIONS]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state).startLoadingEntity('quizQuestions').get()
    },
    [FULFILLED](state, { payload }) {
      const { quizQuestions } = payload
      return EntityFacade(state).loadQuizQuestions(quizQuestions).finishLoadingEntity('quizQuestions').get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state).loadQuizQuestions([]).finishLoadingEntity('quizQuestions').get()
    }
  },
  [ActionTypes.CREATE_QUIZ_QUESTION]: {
    [FULFILLED](state, { payload }) {
      const { createQuizQuestion } = payload
      return EntityFacade(state).createQuizQuestion(createQuizQuestion).get()
    }
  },
  [ActionTypes.UPDATE_QUIZ_QUESTION]: {
    [FULFILLED](state, { meta }) {
      const { question } = meta
      return EntityFacade(state).updateQuizQuestion(question).get()
    }
  },
  [ActionTypes.DELETE_QUIZ_QUESTION]: {
    [FULFILLED](state, { meta }) {
      const { question } = meta
      return EntityFacade(state).deleteQuizQuestion(question).get()
    }
  }
}
