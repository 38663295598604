import typeToReducer from 'type-to-reducer'
import { PENDING, REJECTED, FULFILLED } from '../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../constants/ActionType'
import DashboardFacade from '../facades/dashboard'

export default typeToReducer(
  {
    [ActionTypes.FETCH_FINAL_USAGE_DASHBOARD]: {
      [PENDING](state) {
        return DashboardFacade(state).startLoadingFinalUsage().get()
      },
      [FULFILLED](state, { payload }) {
        const { data } = payload
        return DashboardFacade(state).finishLoadingFinalUsage(data).get()
      },
      [REJECTED](state, { payload }) {
        return DashboardFacade(state).finishLoadingFinalUsage(['']).get()
      }
    },
    [ActionTypes.FETCH_MATCH_USAGE_DASHBOARD]: {
      [PENDING](state) {
        return DashboardFacade(state).startLoadingMatchUsage().get()
      },
      [FULFILLED](state, { payload }) {
        const { data } = payload
        return DashboardFacade(state).finishLoadingMatchUsage(data).get()
      },
      [REJECTED](state, { payload }) {
        return DashboardFacade(state).finishLoadingMatchUsage(['']).get()
      }
    },
    [ActionTypes.FETCH_CHANNEL_USAGE_DASHBOARD]: {
      [PENDING](state) {
        return DashboardFacade(state).startLoadingChannelUsage().get()
      },
      [FULFILLED](state, { payload }) {
        const { data } = payload
        return DashboardFacade(state).finishLoadingChannelUsage(data).get()
      },
      [REJECTED](state, { payload }) {
        return DashboardFacade(state).finishLoadingChannelUsage().get()
      }
    },
    [ActionTypes.FETCH_UNIQUE_USAGE_DASHBOARD]: {
      [PENDING](state) {
        return DashboardFacade(state).startLoadingUniqueUsage().get()
      },
      [FULFILLED](state, { payload }) {
        const { data } = payload
        return DashboardFacade(state).finishLoadingUniqueUsage(data).get()
      },
      [REJECTED](state, { payload }) {
        return DashboardFacade(state).finishLoadingUniqueUsage().get()
      }
    },
    [ActionTypes.FETCH_OPEN_USAGE_DASHBOARD]: {
      [PENDING](state) {
        return DashboardFacade(state).startLoadingOpenUsage().get()
      },
      [FULFILLED](state, { payload }) {
        const { data } = payload
        return DashboardFacade(state).finishLoadingOpenUsage(data).get()
      },
      [REJECTED](state, { payload }) {
        return DashboardFacade(state).finishLoadingOpenUsage().get()
      }
    },
    [ActionTypes.FETCH_ENGAGEMENT]: {
      [PENDING](state) {
        return DashboardFacade(state).startLoadingEngagement().get()
      },
      [FULFILLED](state, { payload }) {
        const { data } = payload
        return DashboardFacade(state).finishLoadingEngagement(data).get()
      },
      [REJECTED](state, { payload }) {
        return DashboardFacade(state).finishLoadingEngagement().get()
      }
    },
    [ActionTypes.RESET_DASHBOARD_DATA]: (state) => {
      return DashboardFacade(state).resetDashboardData().get()
    }
  },
  DashboardFacade().get()
)
