import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { makeEnhancer as makeReducersEnhancer } from '@redux-tools/reducers'

import thunk from 'redux-thunk'

import promiseMiddleware from 'redux-promise-middleware'
import { createLogger } from 'redux-logger'

import * as reducers from './reducers'
import history from './history'
import removeToken from './store/middleware/removeToken'
import socket from './actions/socket'

const composeEnhancers =
  process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose

const middleware = [routerMiddleware(history), thunk, promiseMiddleware, removeToken()] //, createLogger()]

if (process.env.NODE_ENV !== 'production') {
  middleware.push(
    createLogger({
      predicate: (getState, action) => !action.type.includes('@@redux-form'),
      collapsed: true,
      duration: true
    })
  )
}

const enhancer = composeEnhancers(applyMiddleware(...middleware))

const store = createStore(
  combineReducers({
    router: connectRouter(history),
    ...reducers
  }),
  compose(makeReducersEnhancer(), enhancer)
)

socket(store)

export default store
