import normalizeData from '../../helpers/normalizeData'

export default mergeWith => {
  return {
    loadBroadcastChannels(broadcastChannelsList) {
      mergeWith(({ broadcastChannels }) => {
        broadcastChannels = normalizeData(broadcastChannelsList)
        return { broadcastChannels }
      })
      return this
    }
  }
}
