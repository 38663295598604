export default [
  [
    {
      name: 'Matches (old admin)',
      role: ['admin', 'superCommentator', 'channelCommentator', 'commentator'],
      external: true,
      url: '/matches/rugbyUnion'
    },
    {
      name: 'Matches (new admin)',
      url: '/matches/rugbyUnion',
      slug: ['/matches/:sport', '/matches/:sport/:leagueCId/:season'],
      role: ['admin', 'superCommentator', 'channelCommentator']
    },
    {
      name: 'Live Matches (new admin)',
      url: '/matches/all',
      slug: ['/matches/all'],
      role: ['admin', 'superCommentator', 'commentator']
    },
    {
      name: 'Leagues',
      role: ['admin'],
      external: true,
      url: '/leagues/rugbyUnion'
    },
    {
      name: 'Teams',
      role: ['admin'],
      external: true,
      url: '/teams/rugbyUnion'
    },
    {
      name: 'Seasons',
      role: ['admin'],
      external: true,
      url: '/seasons/rugbyUnion'
    },
    {
      name: 'Players',
      role: ['admin'],
      external: true,
      url: '/players/list/rugbyUnion'
    }
  ],
  [
    {
      name: 'Partners',
      url: '/partners',
      slug: ['/partners'],
      role: ['admin']
    },
    {
      name: 'Broadcast Programmes',
      url: '/broadcastProgrammes',
      slug: ['/broadcastProgrammes'],
      role: ['admin', 'superCommentator']
    },
    {
      name: 'Broadcast Providers',
      url: '/broadcastProviders',
      slug: ['/broadcastProviders'],
      role: ['admin']
    },
    {
      name: 'Prediction Questions',
      url: '/predictionQuestions',
      slug: ['/predictionQuestions'],
      role: ['admin']
    },
    {
      name: 'Quizzes',
      url: '/quizzes',
      slug: ['/quizzes'],
      role: ['admin', 'superCommentator', 'quizAdmin', 'quizConsumer']
    },
    {
      name: 'Quiz Question',
      url: '/quizQuestions',
      slug: ['/quizQuestions'],
      role: ['admin', 'superCommentator', 'quizAdmin', 'quizConsumer']
    },
    {
      name: 'Admin',
      role: ['admin'],
      slug: ['/admin'],
      url: '/admin'
    },
    {
      name: 'Parser',
      role: ['admin'],
      external: true,
      url: '/matches/parser'
    },
    {
      name: 'Media Channels',
      role: ['admin'],
      external: true,
      url: '/media-channels'
    },
    {
      name: 'Users',
      role: ['admin'],
      external: true,
      url: '/users/list'
    },
    {
      name: 'Ads',
      role: ['admin'],
      external: true,
      url: '/ads'
    },
    {
      name: 'Venues',
      role: ['admin'],
      external: true,
      url: '/venues'
    },
    {
      name: 'Odds',
      role: ['admin'],
      external: true,
      url: '/odds'
    },
    {
      name: 'Tribe IDs',
      role: ['admin'],
      external: true,
      url: '/tribeids'
    },
    {
      name: 'PNS Log',
      role: ['admin'],
      external: true,
      url: '/system/pnsLogger'
    },
    {
      name: 'Admin',
      role: ['admin', 'superCommentator'],
      external: true,
      url: '/admin'
    },
    {
      name: 'Logout',
      role: ['all'],
      external: true,
      url: '/logout'
    }
  ]
]
