import { createPromisifiedFacade } from 'facade-tools'
import * as factTypes from '../values/factType/'

const initialState = {
  soccer: factTypes.soccer,
  rugbyUnion: factTypes.rugbyUnion,
  rugbyLeague: factTypes.rugbyLeague,
  americanFootball: factTypes.americanFootball,
  aussieRules: factTypes.aussieRules
}

const FactTypeFacade = createPromisifiedFacade((mergeWith) => {
  return {
    loadFactTypeByMatch(matchCId, factTypes) {
      mergeWith(({ factType }) => {
        factTypes = factTypes.map((factType) => {
          return {
            title: factType.title,
            type: factType.type,
            scoreIncrementFactor: factType.scoreIncrementFactor,
            penaltyIncrementFactor: factType.penaltyIncrementFactor,
            behindsIncrementFactor: factType.behindsIncrementFactor,
            goalsIncrementFactor: factType.goalsIncrementFactor,
            matchLifeCycleFact: factType.matchLifeCycleFact || false
          }
        })
        factType = {
          ...factType,
          [matchCId]: factTypes
        }
        return factType
      })
      return this
    }
  }
}, initialState)

export default FactTypeFacade
