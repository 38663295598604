const redirectUserBasedOnRole = ({ adminUser }, { goToIntro, goToDefaultMatchList, goToQuizzes }) => {
  return () => {
    if (adminUser) {
      if (adminUser.role === 'trialCommentator') {
        goToIntro()
      } else if (['quizAdmin', 'quizConsumer'].includes(adminUser.role)) {
        goToQuizzes()
      } else if (adminUser.role === 'channelAdmin') {
        window.location.href = 'https://admin.tribesportsapp.com'
        return null
      } else {
        goToDefaultMatchList()
      }
    }
  }
}

export default redirectUserBasedOnRole
