import { createFacade } from 'facade-tools'

const initialState = {
  loader: {
    allMatchMyAvailabilty: null,
    allMatchSelectCommentator: null,
    loadLineup: null,
    saveLineup: null,
    saveMatchNote: null,
    saveTvNote: null,
    saveCommentatorConfirmation: null,
    saveBroadcastProviderIds: null,
    saveBroadcastProgrammeRelevance: null,
    saveCricketFact: null,
    saveDescription: null
  },
  scrollPostion: { listByDate: 0, listBySeason: 0 },
  matchFromLiveList: { matchCId: null, queryString: null },
}

const MiscellaneousFacade = createFacade((mergeWith) => {
  return {
    startLoading({ loaderName, id }) {
      mergeWith(({ loader }) => {
        loader = { ...loader, [loaderName]: id || true }
        return { loader }
      })
      return this
    },
    finishLoading({ loaderName }) {
      mergeWith(({ loader }) => {
        loader = { ...loader, [loaderName]: null }
        return { loader }
      })
      return this
    },
    setScrollPostion(page, position) {
      mergeWith(({ scrollPostion }) => {
        scrollPostion = { ...scrollPostion, [page]: position }
        return { scrollPostion }
      })
      return this
    },
    setMatchNavigationToLivePage(customId, searchString) {
      mergeWith(({ matchFromLiveList }) => {
        if (customId && searchString) matchFromLiveList = { ...matchFromLiveList, matchCId: customId, queryString: searchString }
        return { matchFromLiveList }
      })
      return this
    },
    removeNavigatedMatchFromStore() {
      mergeWith(({ matchFromLiveList }) => {
        ;['matchCId', 'queryString'].forEach((e) => (matchFromLiveList[e] = null))
        return { matchFromLiveList }
      })
      return this
    },
  }
}, initialState)

export default MiscellaneousFacade
