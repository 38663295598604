import { createPromisifiedFacade } from 'facade-tools'

const initialState = {
  error: null,
  partner: null, // partner object
  partnerMatchUsage: null, // usage data from mixpanel
  partnerChannelUsage: null, // usage data from mixpanel
  matchesLoading: false, // loading flag
  matchUsageLoading: false, // loading flag
  channelUsageLoading: false, // loading flag
  partnerMatchesLoaded: false, // matches in entity store flag
  partnerUsageMatchesLoaded: false, // usage matches in entity store flag
  cpMatches: null,
  cpMatchesLoading: false,
  allPartnersLoaded: false,
  addPopupIsOpen: null,
  editPartnerId: null,
}

const PartnerFacade = createPromisifiedFacade((mergeWith) => {
  return {
    startLoadingMatches() {
      mergeWith({
        matchesLoading: true,
      })
      return this
    },
    finishLoadingMatches(state) {
      mergeWith({
        matchesLoading: false,
        partnerMatchesLoaded: state,
      })
      return this
    },
    startLoadingUsageMatches() {
      mergeWith({
        matchesLoading: true,
      })
      return this
    },
    finishLoadingUsageMatches(state) {
      mergeWith({
        matchesLoading: false,
        partnerUsageMatchesLoaded: state,
      })
      return this
    },
    openAddPartnerPopup() {
      mergeWith({
        addPopupIsOpen: true,
      })
      return this
    },
    closeAddPartnerPopup() {
      mergeWith({
        addPopupIsOpen: false,
      })
      return this
    },
    openEditPartnerPopup(partnerCId) {
      mergeWith({
        editPartnerId: partnerCId,
      })
      return this
    },
    closeEditPartnerPopup() {
      mergeWith({
        editPartnerId: null,
      })
      return this
    },

    allPartnersLoaded() {
      mergeWith(({ allPartnersLoaded }) => {
        allPartnersLoaded = true
        return { allPartnersLoaded }
      })
      return this
    },
    logInPartner(partner) {
      if (partner) {
        mergeWith({
          partner,
          error: null,
        })
      }

      return this
    },
    logInRejected(error) {
      mergeWith({
        partner: null,
        error,
      })

      return this
    },
    startLoadingMatchUsage() {
      mergeWith({
        matchUsageLoading: true,
      })
      return this
    },
    finishLoadingMatchUsage(data) {
      mergeWith({
        matchUsageLoading: false,
        partnerMatchUsage: data,
      })
      return this
    },
    startLoadingChannelUsage() {
      mergeWith({
        channelUsageLoading: true,
      })
      return this
    },
    finishLoadingChannelUsage(data) {
      mergeWith({
        channelUsageLoading: false,
        partnerChannelUsage: data,
      })
      return this
    },
    resetPartnersData() {
      mergeWith({
        partnerMatchUsage: null,
        partnerChannelUsage: null,
        partnerMatchesLoaded: false,
        partnerUsageMatchesLoaded: false,
      })
      return this
    },
    startLoadingCPMatches() {
      mergeWith({
        cpMatchesLoading: true,
      })
      return this
    },
    finishLoadingCPMatches(matches) {
      mergeWith({
        cpMatchesLoading: false,
        cpMatches: matches.map((m) => m.customId),
      })
      return this
    },
    resetCPMatches() {
      mergeWith({
        cpMatches: null,
      })
      return this
    },
  }
}, initialState)

export default PartnerFacade
