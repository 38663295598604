import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_BROADCAST_PROGRAMMES]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state)
        .startLoadingEntity('broadcastProgrammes')
        .get()
    },
    [FULFILLED](state, { payload }) {
      const { broadcastProgrammes } = payload
      return EntityFacade(state)
        .loadBroadcastProgrammes(broadcastProgrammes)
        .finishLoadingEntity('broadcastProgrammes')
        .get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state)
        .loadBroadcastProgrammes([])
        .finishLoadingEntity('broadcastProgrammes')
        .get()
    }
  }
}
