import normalizeData from '../../helpers/normalizeData'

export default (mergeWith) => {
  return {
    loadAdditionalTableData(tableData) {
      mergeWith(({ additionalTableData }) => {
        additionalTableData = normalizeData(tableData, null, 'tournamentGroupCId')
        return { additionalTableData }
      })
      return this
    },
    resetAdditionalTable() {
      mergeWith({
        additionalTableData: null,
      })
      return this
    },
  }
}
