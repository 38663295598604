import { createFacade } from 'facade-tools'

const initialState = {
  activeUrl: null
}

const loaderFacade = createFacade(mergeWith => {
  return {
    setSidebar(url) {
      mergeWith(sidebar => {
        return { ...sidebar, activeUrl: url }
      })
      return this
    }
  }
}, initialState)

export default loaderFacade
