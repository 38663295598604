import createPromisifiedFacade from 'facade-tools/lib/createPromisifiedFacade'

const initialState = {
  allTeamsLoaded: false,
  addPlayerPopup: { teamCId: null, side: null }
}

const TeamFacade = createPromisifiedFacade(mergeWith => {
  return {
    allTeamsLoaded() {
      mergeWith(({ allTeamsLoaded }) => {
        allTeamsLoaded = true
        return { allTeamsLoaded }
      })
      return this
    },
    openAddPlayerPopup(teamCId, side) {
      mergeWith(({ addPlayerPopup }) => {
        addPlayerPopup = { ...addPlayerPopup, teamCId, side }
        return { addPlayerPopup }
      })
      return this
    },
    closeAddPlayerPopup() {
      mergeWith({
        addPlayerPopup: false
      })
      return this
    },
  }
}, initialState)

export default TeamFacade
