import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'

import loadScreen from '../helpers/loadScreen'
import PrivateRoute from './containers/PrivateRoute'
import UpdateFound from './containers/UpdateFound'

const ScreenRouter = () => {
  return (
    <Fragment>
      <UpdateFound {...{ key: 'updateFound' }} />
      <Switch>
        <PrivateRoute
          {...{
            key: 'commentaryIntro',
            path: '/matches/:sport/:leagueCId/:season/:id/commentary/:intro(1)',
            exact: true,
            component: loadScreen(() => {
              return import('../screens/Commentary/Commentary')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'commentary',
            path: '/matches/:sport/:leagueCId/:season/:id/commentary',
            exact: true,
            sideBar: true,
            roles: ['admin', 'commentator', 'superCommentator', 'channelSuperAdmin', 'channelCommentator'],
            component: loadScreen(() => {
              return import('../screens/Commentary/Commentary')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'redirection',
            path: '/match/:id/commentary',
            exact: true,
            component: loadScreen(() => {
              return import('../screens/Commentary/Redirection')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'redirectionToId',
            path: '/match/:id',
            exact: true,
            roles: ['admin', 'commentator', 'superCommentator', 'channelSuperAdmin', 'channelCommentator'],
            component: loadScreen(() => {
              return import('../screens/Commentary/Redirection')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'allMatches',
            path: '/matches/all',
            sideBar: true,
            exact: true,
            roles: ['admin', 'commentator', 'superCommentator', 'channelSuperAdmin'],
            component: loadScreen(() => {
              return import('../screens/Matches/AllMatchList')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'matchList',
            path: '/matches/:sport',
            exact: true,
            sideBar: true,
            roles: ['admin', 'commentator', 'superCommentator', 'channelSuperAdmin', 'channelCommentator'],
            component: loadScreen(() => {
              return import('../screens/Matches/MatchList')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'allPartners',
            path: '/partners',
            exact: true,
            sideBar: true,
            roles: ['admin'],
            component: loadScreen(() => {
              return import('../screens/Partners/AllPartners')
            })
          }}
        />
        <PrivateRoute
          {...{
            path: '/broadcastProviders',
            exact: true,
            sideBar: true,
            roles: ['admin'],
            component: loadScreen(() => {
              return import('../screens/BroadcastProviders/BroadcastProviders')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'broadcastProgrammes',
            path: '/broadcastProgrammes',
            exact: true,
            sideBar: true,
            roles: ['admin', 'superCommentator'],
            component: loadScreen(() => {
              return import('../screens/BroadcastProgrammes/BroadcastProgrammes')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'matchListWithSeasonId',
            path: '/matches/:sport/:leagueCId/:seasonCId',
            exact: true,
            sideBar: true,
            roles: ['admin', 'commentator', 'superCommentator', 'channelSuperAdmin', 'channelCommentator'],
            component: loadScreen(() => {
              return import('../screens/Matches/MatchList')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'lineup',
            path: '/match/:id/lineup',
            exact: true,
            roles: ['admin', 'commentator', 'superCommentator', 'channelSuperAdmin', 'channelCommentator'],
            component: loadScreen(() => {
              return import('../screens/Lineup/Lineup')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'intro',
            path: '/intro',
            exact: true,
            component: loadScreen(() => {
              return import('../screens/Commentary/Intro')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'default',
            path: '/',
            exact: true,
            component: loadScreen(() => {
              return import('../screens/Default/container/Default')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'admin',
            path: '/admin',
            exact: true,
            sideBar: true,
            roles: ['admin'],
            component: loadScreen(() => {
              return import('../screens/Admin/container/Admin')
            })
          }}
        />
        <Route
          {...{
            key: 'notAuthorized',
            path: '/not-authorized',
            exact: true,
            component: loadScreen(() => {
              return import('../screens/NotAuthorized')
            })
          }}
        />
        <Route
          {...{
            key: 'partnerSecret',
            path: '/partners/:partnerId/:partnerSecret',
            exact: true,
            component: loadScreen(() => {
              return import('../screens/Partners/container/Partner')
            })
          }}
        />
        <Route
          {...{
            key: 'partnerSecretPage',
            path: '/partners/:partnerId/:partnerSecret/:page',
            exact: true,
            component: loadScreen(() => {
              return import('../screens/Partners/container/Partner')
            })
          }}
        />
        <Route
          {...{
            key: 'partnerSecretPageMonth',
            path: '/partners/:partnerId/:partnerSecret/:page/:month',
            exact: true,
            component: loadScreen(() => {
              return import('../screens/Partners/container/Partner')
            })
          }}
        />
        <Route
          {...{
            key: 'admin',
            path: '/admin',
            exact: true,
            component: loadScreen(() => {
              return import('../screens/Admin/container/Admin')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'quizzes',
            path: '/quizzes',
            exact: true,
            sideBar: true,
            roles: ['admin', 'superCommentator', 'quizAdmin', 'quizConsumer'],
            component: loadScreen(() => {
              return import('../screens/Quizes/List')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'quiz',
            path: '/quizes/:quizId',
            exact: true,
            sideBar: true,
            roles: ['admin', 'superCommentator', 'quizAdmin', 'quizConsumer'],
            component: loadScreen(() => {
              return import('../screens/Quizes/Quiz')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'quizQuestions',
            path: '/quizQuestions',
            exact: true,
            sideBar: true,
            roles: ['admin', 'superCommentator', 'quizAdmin', 'quizConsumer'],
            component: loadScreen(() => {
              return import('../screens/Quizes/Questions')
            })
          }}
        />
        <Route
          {...{
            key: 'dashboard',
            path: '/dashboard/:partnerId/:partnerSecret',
            exact: true,
            sideBar: false,
            roles: ['admin'],
            component: loadScreen(() => {
              return import('../screens/Dashboard')
            })
          }}
        />
        <Route
          {...{
            key: 'dashboard',
            path: '/dashboard/:partnerId/:partnerSecret/:month',
            exact: true,
            sideBar: false,
            roles: ['admin'],
            component: loadScreen(() => {
              return import('../screens/Dashboard')
            })
          }}
        />
        <Route
          {...{
            key: 'dashboard',
            path: '/dashboard/:partnerId/:partnerSecret/:from/:to',
            exact: true,
            sideBar: false,
            roles: ['admin'],
            component: loadScreen(() => {
              return import('../screens/Dashboard')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'predictionQuestions',
            path: '/predictionQuestions',
            exact: true,
            sideBar: true,
            roles: ['admin'],
            component: loadScreen(() => {
              return import('../screens/ClubhousePredictions/Questions')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'predictionCompetitions',
            path: '/predictionCompetitions',
            exact: true,
            sideBar: true,
            roles: ['admin'],
            component: loadScreen(() => {
              return import('../screens/ClubhousePredictions/Competitions')
            })
          }}
        />
        <PrivateRoute
          {...{
            key: 'rtLog',
            path: '/rtLog',
            exact: true,
            sideBar: true,
            roles: ['admin'],
            component: loadScreen(() => {
              return import('../screens/RtLog')
            })
          }}
        />
      </Switch>
    </Fragment>
  )
}

export default ScreenRouter
