import { cloneDeep } from 'lodash'
import apollo from '../apollo'
import omitDeep from '../helpers/omitDeep'

const query = (q, variables = {}, others = {}) => {
  return new Promise((resolve, reject) => {
    apollo
      .query({
        query: q,
        variables,
        ...others
      })
      .then(({ data }) => {
        data = omitDeep(cloneDeep(data), '__typename')
        if (data.loadMatchesByCustomIds) {
          data.loadMatchesByCustomIds.forEach((match) => {
            Object.keys(match).map((key) => {
              if (!match[key]) delete match[key]
              return key
            })
          })
        }
        resolve(data)
      })
      .catch(reject)
  })
}

export default query
