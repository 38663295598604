import { push, replace, go } from 'connected-react-router'

export const goTo = (path) => {
  return push(path)
}

export const goToSkipHistory = (path) => {
  return replace(path)
}

export const goBack = () => {
  return go(-1)
}

export const goToMatchCommentory = ({ customId, sport, leagueCId, seasonCId, intro }, skipHistory = false) => {
  let commentaryUrl = '/commentary'
  if (intro) {
    commentaryUrl = commentaryUrl + '/1'
  }
  return skipHistory
    ? goToSkipHistory('/matches/' + sport + '/' + leagueCId + '/' + seasonCId + '/' + customId + commentaryUrl)
    : goTo('/matches/' + sport + '/' + leagueCId + '/' + seasonCId + '/' + customId + commentaryUrl)
}

export const goToDefaultMatchList = (sport) => {
  sport = sport || 'rugbyUnion'
  return goTo('/matches/' + sport)
}

export const goToQuizzes = () => {
  return goTo('/quizzes')
}
export const goToQuizQuestions = () => {
  return goTo('/quizQuestions')
}

export const goToSeasonMatchList = (sport, leagueCId, seasonCId) => {
  sport = sport || 'rugbyUnion'
  return goTo('/matches/' + sport + '/' + leagueCId + '/' + seasonCId)
}

export const goToLiveMatchList = (searchQueryString) => {
  return goTo('/matches/all' + searchQueryString)
}

export const goToIntro = () => {
  return goTo('/intro')
}

export const goLineup = (matchCId) => {
  return goTo('/match/' + matchCId + '/lineup')
}

export const goToPartnerCodes = (partner) => {
  return goTo(`/partners/${partner.partnerId}/${partner.secret}`)
}
export const goToPartnerUsage = (partner, month = '') => {
  return goTo(`/partners/${partner.partnerId}/${partner.secret}/usage/${month}`)
}
export const goToPartnerMatches = (partner, month = '') => {
  return goTo(`/partners/${partner.partnerId}/${partner.secret}/matches`)
}
export const goToProDashboard = (partner, month = '') => {
  return goTo(`/partners/${partner.partnerId}/${partner.secret}/pro-dashboard`)
}
export const goToAmateurRugbyDashboard = (partner) => {
  return goTo(`/partners/${partner.partnerId}/${partner.secret}/amateur-rugby`)
}
export const goToQuiz = (quizId) => {
  return goTo(`/quizes/${quizId}`)
}
export const goToDashboard = (month = '', partnerId, partnerSecret) => {
  return goTo(`/dashboard/${partnerId}/${partnerSecret}/${month}`)
}
export const goToDashboardFromTo = (from, to, partnerId, partnerSecret) => {
  return goTo(`/dashboard/${partnerId}/${partnerSecret}/${from}/${to}`)
}
export const updateQueryString = (searchString) => {
  return replace({
    search: searchString,
  })
}
