import moment from 'moment'
const parseSoccer = (match, fact) => {
  if (['period_started', 'period_over'].indexOf(fact.type) >= 0) {
    if (fact.period === 'P1' || fact.period === 'H') match.periodTA = fact.time
    else if (fact.period === 'P2') match.periodTA = moment(fact.time).subtract(45, 'minutes').format()
    else if (fact.period === 'X1') match.periodTA = moment(fact.time).subtract(90, 'minutes').format()
    else if (fact.period === 'X2') match.periodTA = moment(fact.time).subtract(105, 'minutes').format()
    else match.periodTA = null
  }
  return match
}
export default parseSoccer