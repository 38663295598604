import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_BROADCAST_PROVIDERS]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state)
        .startLoadingEntity('broadcastProviders')
        .get()
    },
    [FULFILLED](state, { payload }) {
      const { broadcastProviders } = payload
      return EntityFacade(state)
        .loadBroadcastProviders(broadcastProviders)
        .finishLoadingEntity('broadcastProviders')
        .get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state)
        .loadBroadcastProviders([])
        .finishLoadingEntity('broadcastProviders')
        .get()
    }
  },
  [ActionTypes.CREATE_BROADCAST_PROVIDER]: {
    [FULFILLED](state, { payload }) {
      const { createBroadcastProviders } = payload
      return EntityFacade(state)
        .createBroadcastProvider(createBroadcastProviders)
        .get()
    }
  },
  [ActionTypes.UPDATE_BROADCAST_PROVIDER]: {
    [FULFILLED](state, { meta }) {
      const { broadcastProviders } = meta
      return EntityFacade(state)
        .updateBroadcastProvider(broadcastProviders)
        .get()
    }
  }
}
