import normalizeData from '../../helpers/normalizeData'

export default (mergeWith) => {
  return {
    loadCricketStats(cricketStatsList, matchCId) {
      mergeWith(({ cricketStats }) => {
        const dataArray = cricketStatsList ? [cricketStatsList] : []
        cricketStats = normalizeData(dataArray, cricketStats)
        if (cricketStats && cricketStats.byId && !cricketStats.byId[matchCId]) {
          cricketStats.byId[matchCId] = {}
          cricketStats.allIds.push(matchCId)
        }
        return { cricketStats }
      })
      return this
    },
    updateCricketStats(matchCId, newCricketStats) {
      mergeWith(({ cricketStats }) => {
        newCricketStats = { ...newCricketStats, customId: matchCId }
        cricketStats = normalizeData([newCricketStats], cricketStats)
        if (cricketStats.byId[matchCId]) cricketStats.byId[matchCId] = { ...cricketStats.byId[matchCId], ...newCricketStats }
        cricketStats = { ...cricketStats }
        return { cricketStats }
      })
      return this
    },
    updateCricketStatsViaSocket(matchCId, { stats }) {
      mergeWith(({ cricketStats }) => {
        if (cricketStats && cricketStats.byId) {
          cricketStats.byId[matchCId] = { ...cricketStats.byId[matchCId], ...stats }
          cricketStats = { ...cricketStats }
        }
        return { cricketStats }
      })
      return this
    },
  }
}
