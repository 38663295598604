import createFacade from 'facade-tools/lib/createFacade'
import helpItems from '../values/helpItems'

const initialState = { helpItems }

const HelpItemFacade = createFacade((mergeWith) => {
  return {}
}, initialState)

export default HelpItemFacade
