import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'emotion-theming'
import { ConnectedRouter } from 'connected-react-router'
import 'bootstrap/dist/css/bootstrap.css'

import ScreenRouter from './components/ScreenRouter'
import store from './store'
import DefaultTheme from './themes/DefaultTheme'
import history from './history'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

class App extends Component {
  componentDidMount() {
    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = []
    window.CRISP_WEBSITE_ID = '82f9373a-1580-4dee-bda7-6d9aedf69562'
    ;(function () {
      var d = document
      var s = d.createElement('script')

      s.src = 'https://client.crisp.chat/l.js'
      s.async = 1
      d.getElementsByTagName('head')[0].appendChild(s)
    })()
  }
  render() {
    return (
      <Provider {...{ store }}>
        <ConnectedRouter {...{ history }}>
          <ThemeProvider {...{ theme: DefaultTheme }}>
            <ScreenRouter />
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    )
  }
}

export default App
