import typeToReducer from 'type-to-reducer'
import { FULFILLED } from '../helpers/reduxPromiseMiddleware'

import TokenFacade from '../facades/token'
import * as ActionTypes from '../constants/ActionType'

export default typeToReducer(
  {
    [ActionTypes.LOG_IN_PARTNER]: {
      [FULFILLED](state, { payload }) {
        const { accessToken } = payload.partner || {}
        return TokenFacade(state)
          .setAccessToken(accessToken)
          .get()
      }
    },
    [ActionTypes.SET_TOKEN](state, { payload }) {
      const { token } = payload
      return TokenFacade(state)
        .setAccessToken(token)
        .get()
    },
    [ActionTypes.REMOVE_TOKEN](state, { payload }) {
      return TokenFacade(state)
        .removeAccessToken()
        .get()
    }
  },
  TokenFacade().get()
)
