import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_CRICKET_STATS]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state)
        .startLoadingEntity('cricketStats')
        .get()
    },
    [FULFILLED](state, { payload, meta }) {
      let { cricketStats } = payload
      const { matchCId } = meta
      return EntityFacade(state)
        .loadCricketStats(cricketStats, matchCId)
        .finishLoadingEntity('cricketStats')
        .get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state)
        .loadCricketStats([])
        .finishLoadingEntity('cricketStats')
        .get()
    }
  },
  [ActionTypes.UPDATE_CRICKET_STATS]: {
    [FULFILLED](state, { meta }) {
      const { matchCId, cricketStats } = meta
      return EntityFacade(state)
        .updateCricketStats(matchCId, cricketStats)
        .get()
    }
  }
}
