import normalizeData from '../../helpers/normalizeData'

export default (mergeWith) => {
  return {
    loadBroadcastProgrammes(broadcastProgrammesList) {
      mergeWith(({ broadcastProgrammes }) => {
        broadcastProgrammes = normalizeData(broadcastProgrammesList)
        return { broadcastProgrammes }
      })
      return this
    }
  }
}
