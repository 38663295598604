import { css } from 'emotion'

const UpdateFoundStyles = css`
    .alert {
        position: fixed;
        z-index: 999999;
        width: 100%;
        text-align: center;
        height: 35px;
        padding: 2px;
        border-radius: 0;
        font-size: 14px;
        line-height: 28px;
    }
    .link {
        margin-left: 10px;
        color: #3a97dc;
        font-size: 14px;
        cursor: pointer;
    }
    div + div.row .sidebar,
    div + div.row .all-match-selector,
    div + div.row .match-selector {
        top: 35px !important;
    }

    div + div.row .sidebar-margin {
        margin-top: 35px !important;
    }

    div + div.row .match-header-sticky-header,
    div + div.row .match-header {
        top: 103px;
    }
`
export default UpdateFoundStyles
