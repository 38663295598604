import typeToReducer from 'type-to-reducer'
import { PENDING, REJECTED, FULFILLED } from '../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../constants/ActionType'
import TeamFacade from '../facades/team'

export default typeToReducer(
  {
    [ActionTypes.LOAD_TEAM]: {
      [PENDING](state, { payload }) {
        return TeamFacade(state)
          .startLoading()
          .get()
      },
      [FULFILLED](state, { payload }) {
        return TeamFacade(state)
          .allTeamsLoaded()
          .finishLoading()
          .get()
      },
      [REJECTED](state, { payload }) {
        return TeamFacade(state)
          .allTeamsLoaded()
          .finishLoading()
          .get()
      },
    },
    [ActionTypes.OPEN_ADD_PLAYER_POPUP]: (state, { payload }) => {
      const { teamCId, side } = payload
      return TeamFacade(state)
        .openAddPlayerPopup(teamCId, side)
        .get()
    },
    [ActionTypes.CLOSE_ADD_PLAYER_POPUP]: (state) => {
      return TeamFacade(state)
        .closeAddPlayerPopup()
        .get()
    }
  },
  TeamFacade().get()
)
