import { prepareValuesForMatchAndFactOnAddBreak } from './../../../helpers/commentary/cricket/prepareBreakData'
import addBreakTypes from './../../../values/cricket/addBreakTypes'
import { find } from 'lodash'
import prepareCricketMatch from '@tribe/cricket'

const parseCricket = (match, fact, teams) => {
  if (['edited', 'deleted'].indexOf(fact.status) === -1) {
    const inning = fact.inning
    if (inning > 0 && match.inningScore && match.inningScore[inning - 1]) {
      if (fact.displayScore && typeof fact.displayScore === 'string') match.inningScore[inning - 1].displayScore = fact.displayScore
      if (fact.matchDisplayOvers && typeof fact.matchDisplayOvers === 'string') match.inningScore[inning - 1].displayOvers = fact.matchDisplayOvers
    }
  }
  if (fact.type === 'period_start' && fact.match.periodStatus) {
    const breakData = find(addBreakTypes, { period: fact.period })
    const { matchStatus, status, matchStatusMytribe, matchStatusChannel, matchStatusShort, matchStatusLong } = prepareValuesForMatchAndFactOnAddBreak(breakData.type, match)
    match = { ...match, matchStatus, status, periodStatus: matchStatus, matchStatusMytribe, matchStatusChannel, matchStatusShort, matchStatusLong }
    match = prepareCricketMatch(match, teams)
  }

  if (fact.type === 'match_started' && fact.match.status) {
    const matchData = {
      periodStatus: '',
      matchStatus: '',
      currentBall: 1,
      currentOver: 1
    }
    match = { ...match, ...matchData }
  }
  return match
}
export default parseCricket
