import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_LEAGUE]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state)
        .startLoadingEntity('leagues')
        .get()
    },
    [FULFILLED](state, { payload }) {
      const { league } = payload
      return EntityFacade(state)
        .loadLeague(league)
        .finishLoadingEntity('leagues')
        .get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state)
        .loadLeague([])
        .finishLoadingEntity('leagues')
        .get()
    }
  }
}
