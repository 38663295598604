import { createPromisifiedFacade } from 'facade-tools'

const initialState = {
  channelUsageLoading: false,
  dashboardChannelUsage: null,
  matchUsageLoading: false,
  dashboardMatchUsage: null,
  openUsageLoading: false,
  dashboardOpenUsage: null,
  dashboardUniqueUsage: null,
  uniqueUsageLoading: false,
  dashboardFinalUsage: null,
  finalUsageLoading: false,

  engagement: null,
  engagementLoading: false
}

const DashboardFacade = createPromisifiedFacade((mergeWith) => {
  return {
    startLoadingFinalUsage() {
      mergeWith({
        finalUsageLoading: true
      })
      return this
    },
    finishLoadingFinalUsage(data) {
      mergeWith({
        finalUsageLoading: false,
        dashboardFinalUsage: data
      })
      return this
    },
    startLoadingMatchUsage() {
      mergeWith({
        matchUsageLoading: true
      })
      return this
    },
    finishLoadingMatchUsage(data) {
      mergeWith({
        matchUsageLoading: false,
        dashboardMatchUsage: data
      })
      return this
    },
    startLoadingChannelUsage() {
      mergeWith({
        channelUsageLoading: true
      })
      return this
    },
    finishLoadingChannelUsage(data) {
      mergeWith({
        channelUsageLoading: false,
        dashboardChannelUsage: data
      })
      return this
    },
    startLoadingOpenUsage() {
      mergeWith({
        openUsageLoading: true
      })
      return this
    },
    finishLoadingOpenUsage(data) {
      mergeWith({
        openUsageLoading: false,
        dashboardOpenUsage: data
      })
      return this
    },
    startLoadingUniqueUsage() {
      mergeWith({
        uniqueUsageLoading: true
      })
      return this
    },
    finishLoadingUniqueUsage(data) {
      mergeWith({
        uniqueUsageLoading: false,
        dashboardUniqueUsage: data
      })
      return this
    },
    startLoadingEngagement() {
      mergeWith({
        engagementLoading: true
      })
      return this
    },
    finishLoadingEngagement(data) {
      mergeWith({
        engagementLoading: false,
        engagement: data
      })
      return this
    },
    resetDashboardData() {
      mergeWith({ ...initialState })
      return this
    }
  }
}, initialState)

export default DashboardFacade
