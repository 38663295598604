import normalizeData from '../../helpers/normalizeData'

export default (mergeWith) => {
  return {
    loadExternalIds(extIds) {
      mergeWith(({ externalIds }) => {
        externalIds = normalizeData(extIds, null, 'extId')
        externalIds.byCustomId = {}
        extIds.forEach((extId) => {
          if (!externalIds.byCustomId[extId.customId]) externalIds.byCustomId[extId.customId] = []
          externalIds.byCustomId[extId.customId].push(extId.extId)
        })
        return { externalIds }
      })
      return this
    }
  }
}
