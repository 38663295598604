import { PENDING, REJECTED, FULFILLED } from '../../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../../constants/ActionType'
import EntityFacade from '../../facades/entity'

export default {
  [ActionTypes.LOAD_EXTERNAL_IDS]: {
    [PENDING](state, { payload }) {
      return EntityFacade(state).startLoadingEntity('externalIds').get()
    },
    [FULFILLED](state, { payload }) {
      const { externalId } = payload
      return EntityFacade(state).loadExternalIds(externalId).finishLoadingEntity('externalIds').get()
    },
    [REJECTED](state, { payload }) {
      return EntityFacade(state).loadExternalIds([]).finishLoadingEntity('externalIds').get()
    }
  }
}
