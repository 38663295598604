import gql from 'graphql-tag'

export default gql`
  mutation setMatchAvailability($matchCId: String!, $availability: String, $availabilityFrom: String, $availabilityTo: String, $slot: Int, $day: Int) {
    setMatchAvailability(matchCId: $matchCId, availability: $availability, availabilityFrom: $availabilityFrom, availabilityTo: $availabilityTo, slot: $slot, day: $day) {
      availability
      availabilitySlots
      availabilityMetric {
        available
        tbc
        notAvailable
        day2
        day3
        day4
        day5
      }
      availabileCommentators {
        adminUser
        status
        availabilitySlots
      }
    }
  }
`
