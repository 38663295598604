import typeToReducer from 'type-to-reducer'
import { PENDING, REJECTED, FULFILLED } from '../helpers/reduxPromiseMiddleware'

import FactTypeFacade from '../facades/factType'
import { compact } from 'lodash'

import * as ActionTypes from '../constants/ActionType'

export default typeToReducer(
  {
    [ActionTypes.LOAD_FACT_TYPE_MATCH]: {
      [PENDING](state, { payload }) {
        return FactTypeFacade(state)
          .startLoading()
          .get()
      },
      [FULFILLED](state, { meta, payload }) {
        const factType = compact(payload)
        const { customId: matchCId } = meta.match
        return FactTypeFacade(state)
          .loadFactTypeByMatch(matchCId, factType)
          .finishLoading()
          .get()
      },
      [REJECTED](state, { payload }) {
        return FactTypeFacade(state)
          .loadFactType([])
          .finishLoading()
          .get()
      }
    }
  },
  FactTypeFacade().get()
)
