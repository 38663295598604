export default [
  { title: 'Commentary', type: 'commentary' },
  { title: 'Try', type: 'try', shouldUpdateMatch: true, scoreIncrementFactor: 4 },
  { title: 'Try - Penalty Try', type: 'penalty_try', shouldUpdateMatch: true, scoreIncrementFactor: 4 },
  { title: 'Conversion', type: 'conversion', shouldUpdateMatch: true, scoreIncrementFactor: 2 },
  { title: 'Missed Conversion', type: 'missed_conversion' },
  { title: 'Penalty', type: 'penalty_points', shouldUpdateMatch: true, scoreIncrementFactor: 2 },
  { title: 'Missed Penalty', type: 'missed_penalty' },
  { title: 'Drop Goal', type: 'drop_goal', shouldUpdateMatch: true, scoreIncrementFactor: 1 },
  { title: 'Missed Drop Goal', type: 'missed_drop_goal' },
  { title: 'Yellow Card', type: 'yellow_card' },
  { title: 'Red Card', type: 'red_card' },
  { title: 'Injury', type: 'injury' },
  {
    title: 'Start Match',
    type: 'start_first_half',
    is_dummy: true,
    shouldUpdateMatch: true,
    matchLifeCycleFact: true,
    matchUpdate: {
      status: 'inprogress',
      period: 'P1',
      periodStatus: 'period_started',
      adminUser: true
    },
    splitedFacts: [
      { type: 'match_started', period: '' },
      { type: 'period_started', period: 'P1', periodStatus: 'period_started', periodTime: true }
    ]
  },
  {
    title: 'End 1st Half',
    type: 'end_first_half',
    is_dummy: true,
    shouldUpdateMatch: true,
    showScoreBoard: true,
    matchLifeCycleFact: true,
    splitedFacts: [
      { type: 'period_over', period: 'P1', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'H', periodStatus: 'period_started', periodTime: true, periodTAFactor: 0 }
    ],
    matchUpdate: {
      period: 'H',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'Start 2nd Half',
    type: 'start_second_half',
    is_dummy: true,
    shouldUpdateMatch: true,
    matchLifeCycleFact: true,
    splitedFacts: [
      { type: 'period_over', period: 'H', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'P2', periodStatus: 'period_started', periodTime: true, periodTAFactor: 45 }
    ],
    matchUpdate: {
      period: 'P2',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'End Match',
    type: 'match_ended_without_extra_time',
    showScoreBoard: true,
    shouldUpdateMatch: true,
    matchLifeCycleFact: true,
    splitedFacts: [
      { type: 'period_over', period: 'P2', periodStatus: 'period_over', periodTime: true },
      { type: 'match_ended', period: '' }
    ],
    matchUpdate: {
      status: 'closed',
      periodStatus: 'period_over'
    }
  },
  {
    title: 'Match to Extra Time',
    type: 'match_to_extra_time',
    is_dummy: true,
    shouldUpdateMatch: true,
    showScoreBoard: true,
    splitedFacts: [
      { type: 'period_over', period: 'P2', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'PX1', periodStatus: 'period_started', periodTime: true }
    ],
    matchUpdate: {
      period: 'PX1',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'Start 1st Extra Time',
    type: 'start_first_extra_time',
    is_dummy: true,
    shouldUpdateMatch: true,
    splitedFacts: [
      { type: 'period_over', period: 'PX1', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'X1', periodStatus: 'period_started', periodTime: true, periodTAFactor: 90 }
    ],
    matchUpdate: {
      period: 'X1',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'End 1st Extra Time',
    type: 'end_first_extra_time',
    is_dummy: true,
    shouldUpdateMatch: true,
    showScoreBoard: true,
    splitedFacts: [
      { type: 'period_over', period: 'X1', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'PX2', periodStatus: 'period_started', periodTime: true }
    ],
    matchUpdate: {
      period: 'PX2',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'Start 2nd Extra Time',
    type: 'start_second_extra_time',
    is_dummy: true,
    shouldUpdateMatch: true,
    splitedFacts: [
      { type: 'period_over', period: 'PX2', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'X2', periodStatus: 'period_started', periodTime: true, periodTAFactor: 105 }
    ],
    matchUpdate: {
      period: 'X2',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'End Match',
    type: 'match_ended_after_extra_time',
    shouldUpdateMatch: true,
    showScoreBoard: true,
    splitedFacts: [
      { type: 'period_over', period: 'X2', periodStatus: 'period_over', periodTime: true },
      { type: 'match_ended', period: '' }
    ],
    matchUpdate: {
      status: 'closed',
      periodStatus: 'period_over'
    }
  },
  {
    title: 'End Match',
    type: 'match_ended',
    shouldUpdateMatch: true,
    showScoreBoard: true
  }
]
