import typeToReducer from 'type-to-reducer'
import * as ActionTypes from '../constants/ActionType'
import SidebarFacade from '../facades/sidebar'

export default typeToReducer(
  {
    [ActionTypes.SET_SIDEBAR]: (state, { payload }) => {
      return SidebarFacade(state)
        .setSidebar(payload)
        .get()
    }
  },
  SidebarFacade().get()
)
