export default [
  {
    event: 'partner.auth.response',
    reduxAction: 'SOCKET_AUTHENTICATION'
  },
  {
    event: 'connect',
    reduxAction: 'SOCKET_CONNECTED'
  },
  {
    event: 'core.facts.created',
    reduxAction: 'SOCKET_FACTS_CREATED'
  },
  {
    event: 'core.score.updated',
    reduxAction: 'SOCKET_SCORE_UPDATED'
  },
  {
    event: 'error',
    reduxAction: 'SOCKET_ERROR'
  },
  {
    event: 'core.cricketStats.updated',
    reduxAction: 'SOCKET_CRICKET_STATS_UPDATED'
  },
  {
    event: 'core.cricketStatuses.updated',
    reduxAction: 'SOCKET_CRICKET_STATUSES_UPDATED'
  },
  {
    event: 'admin.match.updated',
    reduxAction: 'SOCKET_MATCH_UPDATED'
  },
  {
    event: 'match.assignedAdmins.updated',
    reduxAction: 'SOCKET_ASSIGNEDADMINS_UPDATED'
  }
]
