import typeToReducer from 'type-to-reducer'
import * as ActionTypes from '../constants/ActionType'
import MiscellaneousFacade from '../facades/miscellaneous'

export default typeToReducer(
  {
    [ActionTypes.START_LOADER]: (state, { payload }) => {
      return MiscellaneousFacade(state)
        .startLoading(payload)
        .get()
    },
    [ActionTypes.FINISH_LOADER]: (state, { payload }) => {
      return MiscellaneousFacade(state)
        .finishLoading(payload)
        .get()
    },
    [ActionTypes.SET_SCROLL_POSITION]: (state, { payload }) => {
      const { page, postion } = payload
      return MiscellaneousFacade(state)
        .setScrollPostion(page, postion)
        .get()
    },
    [ActionTypes.NAVIGATE_MATCH_TO_LIVE_PAGE]: (state, { payload }) => {
      const { customId, searchString } = payload
      return MiscellaneousFacade(state)
        .setMatchNavigationToLivePage(customId, searchString)
        .get()
    },
    [ActionTypes.REMOVE_NAVIGATED_MATCH_FROM_STORE]: (state) => {
      return MiscellaneousFacade(state)
        .removeNavigatedMatchFromStore()
        .get()
    },
  },
  MiscellaneousFacade().get()
)
