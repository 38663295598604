import typeToReducer from 'type-to-reducer'
import { PENDING, REJECTED, FULFILLED } from '../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../constants/ActionType'
import PartnerFacade from '../facades/partner'

export default typeToReducer(
  {
    [ActionTypes.LOAD_MATCHES_BY_SEASON]: {
      [PENDING](state) {
        return PartnerFacade(state).startLoadingMatches().get()
      },
      [FULFILLED](state) {
        return PartnerFacade(state).finishLoadingMatches(true).get()
      },
      [REJECTED](state) {
        return PartnerFacade(state).finishLoadingMatches(false).get()
      },
    },
    [ActionTypes.LOAD_MATCHES_WITH_ADDITIONAL_DATA]: {
      [PENDING](state) {
        return PartnerFacade(state).startLoadingCPMatches().get()
      },
      [FULFILLED](state, { meta, payload }) {
        const { loadMatchesWithAdditionalData } = payload
        return PartnerFacade(state).finishLoadingCPMatches(loadMatchesWithAdditionalData).get()
      },
      [REJECTED](state) {
        return PartnerFacade(state).finishLoadingCPMatches([]).get()
      },
    },
    [ActionTypes.LOAD_PARTNERS]: {
      [FULFILLED](state) {
        return PartnerFacade(state).allPartnersLoaded().get()
      },
    },
    [ActionTypes.LOG_IN_PARTNER]: {
      [PENDING](state) {
        return PartnerFacade(state).startLoading().get()
      },
      [FULFILLED](state, { payload }) {
        const { partner } = payload
        return PartnerFacade(state).logInPartner(partner).finishLoading().get()
      },
      [REJECTED](state, { payload }) {
        return PartnerFacade(state).logInRejected('No partner found').finishLoading().get()
      },
    },
    [ActionTypes.GET_MATCH_USAGE_FROM_MIXPANEL]: {
      [PENDING](state) {
        return PartnerFacade(state).startLoadingMatchUsage().get()
      },
      [FULFILLED](state, { payload }) {
        const { data } = payload
        return PartnerFacade(state).finishLoadingMatchUsage(data).get()
      },
      [REJECTED](state, { payload }) {
        return PartnerFacade(state).finishLoadingMatchUsage().get()
      },
    },
    [ActionTypes.GET_CHANNEL_USAGE_FROM_MIXPANEL]: {
      [PENDING](state) {
        return PartnerFacade(state).startLoadingChannelUsage().get()
      },
      [FULFILLED](state, { payload }) {
        const { data } = payload
        return PartnerFacade(state).finishLoadingChannelUsage(data).get()
      },
      [REJECTED](state, { payload }) {
        return PartnerFacade(state).finishLoadingChannelUsage().get()
      },
    },
    [ActionTypes.RESET_PARTNERS_DATA]: (state) => {
      return PartnerFacade(state).resetPartnersData().get()
    },

    [ActionTypes.LOAD_MATCHES_BY_CUSTOMIDS]: {
      [PENDING](state) {
        return PartnerFacade(state).startLoadingUsageMatches().get()
      },
      [FULFILLED](state) {
        return PartnerFacade(state).finishLoadingUsageMatches(true).get()
      },
      [REJECTED](state) {
        return PartnerFacade(state).finishLoadingUsageMatches(false).get()
      },
    },
    [ActionTypes.OPEN_ADD_PARTNER_POPUP]: (state) => {
      return PartnerFacade(state).openAddPartnerPopup().get()
    },
    [ActionTypes.CLOSE_ADD_PARTNER_POPUP]: (state) => {
      return PartnerFacade(state).closeAddPartnerPopup().get()
    },
    [ActionTypes.OPEN_EDIT_PARTNER_POPUP]: (state, { payload }) => {
      return PartnerFacade(state).openEditPartnerPopup(payload).get()
    },
    [ActionTypes.CLOSE_EDIT_PARTNER_POPUP]: (state) => {
      return PartnerFacade(state).closeEditPartnerPopup().get()
    },
    [ActionTypes.RESET_CP_MATCHES]: (state) => {
      return PartnerFacade(state).resetCPMatches().get()
    },
  },
  PartnerFacade().get()
)
