export default [
  { title: 'Commentary', type: 'commentary' },
  { title: 'Goal', type: 'goal', shouldUpdateMatch: true, scoreIncrementFactor: 6, goalsIncrementFactor: 1 },
  { title: 'Behind', type: 'behind', shouldUpdateMatch: true, scoreIncrementFactor: 1, behindsIncrementFactor: 1 },
  {
    title: 'Start Match',
    type: 'start_first_period',
    is_dummy: true,
    shouldUpdateMatch: true,
    matchLifeCycleFact: true,
    matchUpdate: {
      status: 'inprogress',
      period: 'P1',
      periodStatus: 'period_started',
      adminUser: true
    },
    splitedFacts: [{ type: 'match_started', period: 'P1' }, { type: 'period_started', period: 'P1', periodStatus: 'period_started', periodTime: true }]
  },
  {
    title: 'End 1st Period',
    type: 'end_first_period',
    shouldUpdateMatch: true,
    is_dummy: true,
    showScoreBoard: true,
    matchLifeCycleFact: true,
    splitedFacts: [
      { type: 'period_over', period: 'P1', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'H1', periodStatus: 'period_started', periodTime: true }
    ],
    matchUpdate: {
      period: 'H1',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'Start 2nd Period',
    type: 'start_second_period',
    shouldUpdateMatch: true,
    is_dummy: true,
    matchLifeCycleFact: true,
    splitedFacts: [
      { type: 'period_over', period: 'H1', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'P2', periodStatus: 'period_started', periodTime: true }
    ],
    matchUpdate: {
      period: 'P2',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'End 2nd Period',
    type: 'end_second_period',
    shouldUpdateMatch: true,
    is_dummy: true,
    matchLifeCycleFact: true,
    showScoreBoard: true,
    splitedFacts: [
      { type: 'period_over', period: 'P2', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'H2', periodStatus: 'period_started', periodTime: true }
    ],
    matchUpdate: {
      period: 'H2',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'Start 3rd Period',
    type: 'start_third_period',
    shouldUpdateMatch: true,
    matchLifeCycleFact: true,
    is_dummy: true,
    splitedFacts: [
      { type: 'period_over', period: 'H2', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'P3', periodStatus: 'period_started', periodTime: true }
    ],
    matchUpdate: {
      period: 'P3',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'End 3rd Period',
    type: 'end_third_period',
    shouldUpdateMatch: true,
    matchLifeCycleFact: true,
    is_dummy: true,
    showScoreBoard: true,
    splitedFacts: [
      { type: 'period_over', period: 'P3', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'H3', periodStatus: 'period_started', periodTime: true }
    ],
    matchUpdate: {
      period: 'H3',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'Start 4th Period',
    type: 'start_fourth_period',
    shouldUpdateMatch: true,
    matchLifeCycleFact: true,
    is_dummy: true,
    splitedFacts: [
      { type: 'period_over', period: 'H3', periodStatus: 'period_over', periodTime: true },
      { type: 'period_started', period: 'P4', periodStatus: 'period_started', periodTime: true }
    ],
    matchUpdate: {
      period: 'P4',
      periodStatus: 'period_started'
    }
  },
  {
    title: 'End Match',
    type: 'match_ended_without_extra_time',
    shouldUpdateMatch: true,
    showScoreBoard: true,
    matchLifeCycleFact: true,
    splitedFacts: [{ type: 'period_over', period: 'P4', periodStatus: 'period_over', periodTime: true }, { type: 'match_ended', period: 'P4' }],
    matchUpdate: {
      status: 'closed',
      periodStatus: 'period_over'
    }
  },

  {
    title: 'End Match',
    type: 'match_ended',
    shouldUpdateMatch: true,
    showScoreBoard: true
  }
]
