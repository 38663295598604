import { createSelector } from 'reselect'

const shouldLoadListSelector = (listSelector, loadingSelector) => {
  return createSelector(
    [listSelector, loadingSelector],
    (list, loading) => {
      return !list && !loading
    }
  )
}

export default shouldLoadListSelector
