import Loadable from 'react-loadable'

import ScreenLoader from '../components/ScreenLoader'

const loadScreen = (loader) => {
  return Loadable({
    loader,
    loading: ScreenLoader
  })
}

export default loadScreen
