import typeToReducer from 'type-to-reducer'
import { PENDING, REJECTED, FULFILLED } from '../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../constants/ActionType'
import ReportFacade from '../facades/report'

export default typeToReducer(
  {
    [ActionTypes.LOAD_COMMENTATOR_REPORT]: {
      [PENDING](state, { payload }) {
        return ReportFacade(state)
          .startLoading()
          .get()
      },
      [FULFILLED](state, { payload }) {
        return ReportFacade(state)
          .commentatorReport(payload)
          .finishLoading()
          .get()
      },
      [REJECTED](state, { payload }) {
        return ReportFacade(state)
          .commentatorReport()
          .finishLoading()
          .get()
      }
    }
  },
  ReportFacade().get()
)
