export default [`This commentary tool has been designed as a practice tool, so that anyone can have a go at doing Tribe’s play-by-play text commentary. After completing a match, Tribe may invite those who are good enough / show enough promise, for an opportunity to join it’s commentary team.`,
`Instructions (NOTE: Chrome browser may provide best results):\n
PRE-MATCH:
a) When ready, hit “start” below. You will see the commentary tool and a video of the match.
b) Add pre-match comments using the text field until the match starts (no need to add a clock).
c) Use the dropdown to select “Match Started” and submit when the game kicks off.

LIVE MATCH:
d) Enter match commentary describing the match. Your audience is people who may well not be watching the match, so you should aim to paint a picture. All the users will see is what you enter.
e) For every ‘fact’ (goal, try, yellow card etc) use the dropdown to select and enter those facts. See the list of dropdown options for guidance on what facts are necessary for that sport.
f) Every comment and fact should also carry a clock, entered as whole numbers only (1, 5, 27 etc).
g) To edit a past comment / fact, click on the fact on the right (and ‘update’ after editing).
h) For Half-Time use the “Break Started” fact. For the start of the 2nd half use “Break Over”.

Enjoy.`,
`For examples of what match commentary should look like, find matches that have commentary in the Tribe app.`]
