import typeToReducer from 'type-to-reducer'
import { PENDING, REJECTED, FULFILLED } from '../helpers/reduxPromiseMiddleware'

import * as ActionTypes from '../constants/ActionType'
import LeagueFacade from '../facades/league'

export default typeToReducer(
  {
    [ActionTypes.LOAD_MATCHES_BY_SEASON]: {
      [PENDING](state, { payload }) {
        return LeagueFacade(state)
          .startLoading()
          .get()
      },
      [FULFILLED](state, { meta, payload }) {
        const { leagueCId } = meta
        const { loadMatchesBySeason } = payload
        return LeagueFacade(state)
          .loadMatches(loadMatchesBySeason, leagueCId)
          .finishLoading()
          .get()
      }
    },
    [ActionTypes.LOAD_LEAGUE]: {
      [PENDING](state, { payload }) {
        return LeagueFacade(state)
          .startLoading()
          .get()
      },
      [FULFILLED](state) {
        return LeagueFacade(state)
          .allLeaguesLoaded()
          .finishLoading()
          .get()
      },
      [REJECTED](state, { payload }) {
        return LeagueFacade(state)
          .allLeaguesLoaded()
          .finishLoading()
          .get()
      }
    }
  },
  LeagueFacade().get()
)
