const getVenuesFromMatches = matches => {
  let venues = []
  matches.map(match => {
    if (match.venue) {
      venues.push(match.venue)
    }
    return match
  })
  return venues
}

export default getVenuesFromMatches
