import createPromisifiedFacade from 'facade-tools/lib/createPromisifiedFacade'
import moment from 'moment'
import normalizeData from '../helpers/normalizeData'
import { uniq } from 'lodash'

const initialState = {
  matchesFullyLoaded: [],
  allMatches: null,
  allMatchesLoadedUpto: null,
  editMatch: null,
  addMatch: null,
  editScore: null,
  editFinalScore: null,
  matchLifeCyclePopup: null,
  liveScoreMatchPopup: null,
  editMatchStatusPopup: null,
  bulkEditMode: false,
  editCricketMatch: null
}

const MatchFacade = createPromisifiedFacade((mergeWith) => {
  return {
    addMatchesToFullyLoaded(matchCId) {
      mergeWith(({ matchesFullyLoaded }) => {
        if (matchCId && matchesFullyLoaded.indexOf(matchCId) === -1) matchesFullyLoaded.push(matchCId)
        return { matchesFullyLoaded }
      })
      return this
    },
    openMatchEditPopup(matchCId) {
      mergeWith(({ editMatch }) => {
        editMatch = matchCId
        return { editMatch }
      })
      return this
    },
    openMatchLifeCyclePopup(matchCId) {
      mergeWith(({ matchLifeCyclePopup }) => {
        matchLifeCyclePopup = matchCId
        return { matchLifeCyclePopup }
      })
      return this
    },
    openMatchAddPopup(match) {
      mergeWith(({ addMatch }) => {
        addMatch = match
        return { addMatch }
      })
      return this
    },
    openMatchStatusEditPopup(matchCId) {
      mergeWith(({ editMatchStatusPopup }) => {
        editMatchStatusPopup = matchCId
        return { editMatchStatusPopup }
      })
      return this
    },
    closeMatchStatusEditPopup() {
      mergeWith(({ editMatchStatusPopup }) => {
        editMatchStatusPopup = null
        return { editMatchStatusPopup }
      })
      return this
    },
    closeAddMatchPopup() {
      mergeWith(({ addMatch }) => {
        addMatch = null
        return { addMatch }
      })
      return this
    },
    closeMatchLifeCyclePopup() {
      mergeWith(({ matchLifeCyclePopup }) => {
        matchLifeCyclePopup = null
        return { matchLifeCyclePopup }
      })
      return this
    },
    closeMatchEditPopup() {
      mergeWith(({ editMatch }) => {
        editMatch = null
        return { editMatch }
      })
      return this
    },
    openEditScorePopup(matchCId) {
      mergeWith(({ editScore }) => {
        editScore = matchCId
        return { editScore }
      })
      return this
    },
    closeScoreEditPopup() {
      mergeWith(({ editScore }) => {
        editScore = null
        return { editScore }
      })
      return this
    },
    openLiveScorePopup(matchCId) {
      mergeWith(({ liveScoreMatchPopup }) => {
        liveScoreMatchPopup = matchCId
        return { liveScoreMatchPopup }
      })
      return this
    },
    closeLiveScorePopup() {
      mergeWith(({ liveScoreMatchPopup }) => {
        liveScoreMatchPopup = null
        return { liveScoreMatchPopup }
      })
      return this
    },
    openFinalScoreEditPopup(matchCId) {
      mergeWith(({ editFinalScore }) => {
        editFinalScore = matchCId
        return { editFinalScore }
      })
      return this
    },
    closeFinalScoreEditPopup() {
      mergeWith(({ editFinalScore }) => {
        editFinalScore = null
        return { editFinalScore }
      })
      return this
    },
    loadAllMatch(matches, endDate) {
      mergeWith(({ allMatches, allMatchesLoadedUpto }) => {
        allMatchesLoadedUpto = endDate || moment().toJSON()
        matches = normalizeData(matches)
        allMatches = allMatches ? [...allMatches, ...matches.allIds] : [...matches.allIds]
        allMatches = uniq(allMatches)
        return { allMatches, allMatchesLoadedUpto }
      })
      return this
    },
    resetAllMatches() {
      mergeWith(() => {
        return { allMatches: null, allMatchesLoadedUpto: null }
      })
      return this
    },
    enableBulkEditMode(status) {
      mergeWith(({ bulkEditMode }) => {
        bulkEditMode = status
        return { bulkEditMode }
      })
      return this
    },
    enableCricketDataEditMode(matchCId) {
      mergeWith(({ editCricketMatch }) => {
        editCricketMatch = matchCId
        return { editCricketMatch }
      })
      return this
    },
    closeCricketControlPanelForm() {
      mergeWith(({ editCricketMatch }) => {
        editCricketMatch = null
        return { editCricketMatch }
      })
      return this
    }
  }
}, initialState)

export default MatchFacade
